import { styled } from "@mui/material";
import { CSSProperties } from "react";

const LinkStyleText = styled("span", {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color?: CSSProperties["color"] }>(
  ({ theme, color = theme.palette.primary.main }) => ({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color,
    "&:hover": {
      cursor: "pointer",
      textDecoration: `underline ${color}`,
    },
  }),
);

export default LinkStyleText;
