import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { Box, IconButton, SvgIconProps, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import ProgressMessage from "../utils/ProgressMessage";
import { useErrorAlertManager } from "./ErrorAlertManager";

const CounterIcon = ({
  style,
  failureCount,
}: SvgIconProps & { failureCount?: number }) => {
  const { isAnyError, clearErrorStates, errorAlertCountdown } =
    useErrorAlertManager();
  const { formatMessage } = useIntl();

  return (
    <IconButton
      onClick={() => clearErrorStates()}
      disabled={!isAnyError}
      disableRipple
      sx={{
        color:
          errorAlertCountdown === 0
            ? "warning.main"
            : isAnyError
              ? "primary.main"
              : "action.disabled",
        "&:hover": {
          backgroundColor: "unset",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "180px",
        }}
      >
        <NotificationImportantIcon style={style} />
        <div>
          <Typography>
            {formatMessage({
              defaultMessage: "Query error",
              description: "Table Component query error with alert message",
            })}
          </Typography>
        </div>
        <ProgressMessage
          message={`${formatMessage({
            defaultMessage: "Retry",
            description: "Table Component querying data in progress message",
          })}${failureCount === undefined ? "" : ` ${failureCount}`}`}
        />
        {isAnyError &&
          (errorAlertCountdown > 0 ? (
            <Typography>
              {formatMessage(
                {
                  defaultMessage: "Alert in {counter} seconds",
                  description: "Cdec Window alert counter message",
                },
                {
                  counter: errorAlertCountdown,
                },
              )}
            </Typography>
          ) : (
            <Typography>
              {formatMessage({
                defaultMessage: "Alert!",
                description: "Cdec Window alert message",
              })}
            </Typography>
          ))}
      </Box>
    </IconButton>
  );
};

export default CounterIcon;
