import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { ReactComponent as NotificationsActiveIcon } from "../../icons/NotificationsActive.svg";
import { ReactComponent as NotificationsOffIcon } from "../../icons/NotificationsOff.svg";
import { useAudio } from "./AudioPlayerProvider";

export const NotificationToggleButton = () => {
  const { formatMessage } = useIntl();
  const { isNotificationOn, setIsNotificationOn } = useAudio();

  return (
    <Tooltip
      title={`${formatMessage({
        defaultMessage: "Notification",
        description: "Notification toggle button tooltip",
      })} ${
        isNotificationOn
          ? formatMessage({
              defaultMessage: "Off",
              description: "Notification toggle off tooltip",
            })
          : formatMessage({
              defaultMessage: "On",
              description: "Notification toggle on tooltip",
            })
      }`}
    >
      <IconButton
        onClick={() => setIsNotificationOn(!isNotificationOn)}
        size="large"
        sx={{ color: "common.white" }}
      >
        {isNotificationOn ? (
          <SvgIcon component={NotificationsActiveIcon} color="inherit" />
        ) : (
          <SvgIcon component={NotificationsOffIcon} color="inherit" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default NotificationToggleButton;
