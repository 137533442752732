import { useCreate } from "@mb-pro-ui/utils";
import { ID, Maybe } from "@mb-pro-ui/utils/types";
import { Cdec as _Cdec } from "@mb-pro-ui/utils/types/alarm";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  FabProps,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialActionProps,
  darken,
  listItemTextClasses,
  styled,
  tooltipClasses,
} from "@mui/material";
import { LatLng } from "leaflet";
import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getErrorMessage } from "../../../hooks/useErrorHandler";
import useTimestampFormat from "../../../locales/useTimestampFormat";
import {
  DialogCloseButton,
  SyledDialogTitle,
} from "../../../map/MapMarkerDialog";
import { serializeDateRange } from "../../table/filters/manual/base/DateRangeFilter";
import CoordsLabel from "../../utils/CoordsLabel";
import InactivateDialog from "./InactivateDialog";

type Cdec = Pick<
  _Cdec,
  | "id"
  | "localized-description"
  | "color"
  | "arrived"
  | "sent"
  | "event-code"
  | "account"
  | "unitid"
  | "partition-number"
  | "zone-number"
  | "active"
  | "latitude"
  | "longitude"
> & {
  customer: Maybe<ID | { id: ID; name?: string | null }>;
  event: Maybe<ID | { id: ID }>;
};

type PickedCdec = Pick<
  Cdec,
  | "event-code"
  | "localized-description"
  | "account"
  | "customer"
  | "unitid"
  | "longitude"
  | "latitude"
  | "sent"
  | "arrived"
  | "partition-number"
  | "zone-number"
  | "active"
  | "color"
>;

type CdecModalProps = {
  cdec: PickedCdec;
  open: boolean;
  onClose(): void;
  disableInactivate?: boolean;
};

type MbProFabProps<C extends React.ElementType> = Partial<
  FabProps<C, { component?: C }>
>;

type MbProSpeedDialActionProps = Omit<SpeedDialActionProps, "FabProps"> & {
  tooltipTitle?: SpeedDialActionProps["tooltipTitle"];
};

export const MbProSpeedDialAction = styled(
  <C extends React.ElementType>({
    className,
    ...props
  }: MbProSpeedDialActionProps & MbProFabProps<C> & { className?: string }) => {
    const { TooltipClasses, ...rest } = props;
    return (
      <SpeedDialAction
        {...rest}
        TooltipClasses={{ popper: className, ...TooltipClasses }}
      />
    );
  },
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
  },
}));

const ModalListItemText = ({
  color,
  primaryTypographyProps: { sx: primarySx, ...primaryTypographyRestProps } = {},
  secondaryTypographyProps: {
    sx: secondarySx,
    ...secondaryTypographyRestProps
  } = {},
  ...props
}: ListItemTextProps<"span", "div"> & {
  color?: string;
}) => {
  return (
    <ListItemText
      primaryTypographyProps={{
        component: "span",
        sx: [
          {
            [`&.${listItemTextClasses.root}, &.${listItemTextClasses.primary}`]:
              {
                color: color,
              },
          },
          ...(Array.isArray(primarySx) ? primarySx : [primarySx]),
        ],
        ...primaryTypographyRestProps,
      }}
      secondaryTypographyProps={{
        component: "div",
        sx: [
          {
            overflow: "auto",
            overflowWrap: "break-word",
            maxHeight: (theme) => theme.spacing(7),
          },
          ...(Array.isArray(secondarySx) ? secondarySx : [secondarySx]),
        ],
        ...secondaryTypographyRestProps,
      }}
      {...props}
    />
  );
};

export const CdecModalContents = ({
  cdec,
  disableInactivate,
  latlng,
}: {
  cdec: PickedCdec;
  disableInactivate?: boolean;
  latlng?: LatLng;
}) => {
  const { formatMessage } = useIntl();
  const { formatTimestamp } = useTimestampFormat();
  const { color, customer } = cdec;
  const customerID = typeof customer === "string" ? customer : customer?.id;

  const customerDialActions = [
    {
      name: formatMessage({ defaultMessage: "Edit customer" }),
      href: `/alarm/customers/${customerID}`,
    },
    {
      name: formatMessage({ defaultMessage: "Customer events" }),
      href: `/alarm/events?customer=${customerID}&arrived=${encodeURIComponent(
        serializeDateRange(moment().subtract(1, "days")),
      )}`,
    },
  ];

  const coords = latlng
    ? { lat: latlng.lat, lon: latlng.lng }
    : cdec.latitude && cdec.longitude
      ? { lat: cdec.latitude, lon: cdec.longitude }
      : null;

  return (
    <Grid container>
      <Grid item xs={6}>
        <List dense>
          <ListItem sx={{ paddingRight: "0" }}>
            <ModalListItemText
              color={color}
              primary={
                <FormattedMessage
                  defaultMessage="Event code"
                  description="Cdec felugró részletes nézet eseméykód"
                />
              }
              secondary={
                <Inactivate cdec={cdec} disableInactivate={disableInactivate} />
              }
              secondaryTypographyProps={{
                sx: {
                  overflow: "initial",
                },
              }}
            />
          </ListItem>

          {customer ? (
            <ListItem>
              <ModalListItemText
                color={color}
                primary={
                  <FormattedMessage defaultMessage="Event description" />
                }
                secondary={cdec["localized-description"] ?? "N/A"}
              />
            </ListItem>
          ) : null}

          <ListItem>
            <ModalListItemText
              color={color}
              primary={formatMessage({ defaultMessage: "Customer" })}
              secondaryTypographyProps={{
                component: "div",
                width: "200%",
                sx: {
                  maxHeight: "initial",
                },
              }}
              secondary={
                customer ? (
                  <SpeedDial
                    ariaLabel="Customer speed dial"
                    FabProps={{
                      size: "small",
                      variant: "extended",
                      sx: {
                        backgroundColor: color,
                        "&:hover": {
                          backgroundColor: darken(color, 0.2),
                        },
                      },
                    }}
                    icon={cdec.account}
                    direction="right"
                  >
                    {cdec.customer
                      ? customerDialActions.map((action, i) => (
                          <MbProSpeedDialAction
                            FabProps={{
                              size: "small",
                              variant: "extended",
                              sx: { color, zIndex: 1 },
                              component: Link,
                              to: action.href,
                            }}
                            icon={action.name}
                            key={i}
                          />
                        ))
                      : null}
                  </SpeedDial>
                ) : (
                  `${cdec.account} - ${formatMessage({ defaultMessage: "Unknown customer" })}`
                )
              }
            />
          </ListItem>

          <ListItem>
            <ModalListItemText
              color={color}
              primary={<FormattedMessage defaultMessage="Helios ID" />}
              secondary={cdec.unitid}
            />
          </ListItem>

          <ListItem>
            <ModalListItemText
              color={color}
              primary={`${formatMessage({
                defaultMessage: "Coordinates",
              })}${
                coords
                  ? ` (${formatMessage({
                      defaultMessage: "Longitude",
                    })}, ${formatMessage({
                      defaultMessage: "Latitude",
                    })})`
                  : ""
              }`}
              secondary={coords ? <CoordsLabel {...coords} /> : "N/A"}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={6}>
        <List dense>
          <ListItem>
            <ModalListItemText
              color={color}
              primary={
                <FormattedMessage
                  defaultMessage="Event time"
                  description="Cdec felugró részletes nézet küldve ekkor"
                />
              }
              secondary={formatTimestamp(cdec.sent)}
            />
          </ListItem>
          <ListItem>
            <ModalListItemText
              color={color}
              primary={
                <FormattedMessage
                  defaultMessage="Arrival time"
                  description="Cdec felugró részletes nézet érkezés"
                />
              }
              secondary={formatTimestamp(cdec.arrived)}
            />
          </ListItem>

          <ListItem>
            <ModalListItemText
              color={color}
              primary={
                <FormattedMessage
                  defaultMessage="Partition"
                  description="Cdec felugró részletes nézet partíció"
                />
              }
              secondary={cdec["partition-number"]}
            />
          </ListItem>

          <ListItem>
            <ModalListItemText
              color={color}
              primary={
                <FormattedMessage
                  defaultMessage="Zone"
                  description="Cdec felugró részletes nézet zóna"
                />
              }
              secondary={cdec["zone-number"]}
            />
          </ListItem>
          <ListItem>
            <ModalListItemText
              color={color}
              primary={formatMessage({
                defaultMessage: "Active",
              })}
              secondary={
                <ListItemIcon>
                  {cdec.active ? (
                    <CheckIcon fontSize="small" color="primary" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </ListItemIcon>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export const Inactivate = ({
  cdec,
  disableInactivate,
}: {
  cdec: PickedCdec;
  disableInactivate?: boolean;
}) => {
  const { color, account, "event-code": eventCode } = cdec;

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<ReactElement>();

  const {
    mutate: disableEvent,
    status,
    error,
  } = useCreate("alarm/disabled-events");

  useEffect(() => {
    if (status === "success") {
      setSnackbarMessage(
        <FormattedMessage
          defaultMessage="{account} customer's {eventCode} events inactivated"
          description="Event inactivation success"
          values={{ account, eventCode }}
        />,
      );
      setIsSnackbarOpen(true);
    } else if (status === "error") {
      setSnackbarMessage(
        error ? (
          getErrorMessage(error)
        ) : (
          <FormattedMessage
            defaultMessage="Something went wrong"
            description="Error handler default error message"
          />
        ),
      );
      setIsSnackbarOpen(true);
    }
  }, [status, account, eventCode, error]);

  const label = (
    <FormattedMessage
      defaultMessage="Disable event"
      description="Cdec felugró részletes nézet inaktiválás"
    />
  );

  return (
    <>
      <SpeedDial
        ariaLabel="Event code speed dial"
        FabProps={{
          size: "small",
          variant: "extended",
          sx: {
            backgroundColor: color,
            "&:hover": {
              backgroundColor: darken(color, 0.2),
            },
            zIndex: 1,
          },
        }}
        icon={cdec["event-code"]}
        direction="right"
      >
        <MbProSpeedDialAction
          FabProps={{
            size: "small",
            variant: "extended",
            sx: { color, zIndex: 1 },
          }}
          disabled={!cdec.customer || !cdec.active || disableInactivate}
          onClick={() => setIsAlertOpen(true)}
          icon={label}
        />
      </SpeedDial>

      {cdec.customer !== null ? (
        <InactivateDialog
          open={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          customer={cdec.customer}
          account={cdec.account}
          eventCode={cdec["event-code"]}
          onInactivate={(customer: ID, eventCode: string) => {
            disableEvent({ customer, "event-code": eventCode });
            setIsAlertOpen(false);
          }}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setIsSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

const CdecModal = ({
  cdec,
  open,
  onClose,
  disableInactivate,
}: CdecModalProps) => (
  <Dialog disableScrollLock open={open} onClose={onClose}>
    <SyledDialogTitle sx={{ backgroundColor: cdec.color }}>
      {cdec["localized-description"]}
    </SyledDialogTitle>
    <DialogCloseButton onClick={onClose} />
    <DialogContent sx={{ minWidth: "550px" }}>
      <CdecModalContents cdec={cdec} disableInactivate={!!disableInactivate} />
    </DialogContent>
  </Dialog>
);

export default CdecModal;
