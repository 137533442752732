import {
  ButtonProps,
  IconButton,
  SvgIcon,
  Tooltip,
  styled,
} from "@mui/material";
import { useIntl } from "react-intl";
import useMe from "../../hooks/useMe";
import { ReactComponent as AlarmStopIcon } from "../../icons/AlarmStop.svg";
import AlertBadge from "../utils/AlertBadge";
import { useAudio } from "./AudioPlayerProvider";

interface StopAllAlertsButtonProps {
  onClick?: () => void;
  blink?: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "blink",
})<ButtonProps & { blink: boolean }>(({ theme, blink }) => ({
  color: theme.palette.common.white,
  "@keyframes blinkWhite": {
    "0%, 100%": {},
    "50%": {
      color: theme.palette.error.main,
    },
  },
  ...(blink && {
    animation: "blinkWhite 1s ease-in-out infinite",
  }),
}));

export const StopAllAlertsButton = ({
  onClick,
  blink,
}: StopAllAlertsButtonProps) => {
  const { formatMessage } = useIntl();
  const { stopLoops, isLooping } = useAudio();
  const { data: me, isLoading: isMeLoading } = useMe();

  const interventionAllowed =
    !!me?.["alarm-operator"] &&
    !me["alarm-operator"]["read-only"] &&
    !isMeLoading;

  const handleStopAlertsClick = () => {
    stopLoops();
    onClick?.();
    localStorage.setItem("stop-alerts", Date.now().toString());
  };

  return interventionAllowed ? (
    <Tooltip
      title={formatMessage({
        defaultMessage: "Mute alerts temporary",
        description: "Stop alerts button tooltip",
      })}
    >
      <AlertBadge>
        <StyledIconButton
          onClick={handleStopAlertsClick}
          size="large"
          blink={!!blink || isLooping}
        >
          <SvgIcon component={AlarmStopIcon} />
        </StyledIconButton>
      </AlertBadge>
    </Tooltip>
  ) : null;
};

export default StopAllAlertsButton;
