import moment, { MomentInput } from "moment";
import React, { ForwardedRef, useEffect, useReducer } from "react";
import { useIntl } from "react-intl";

const Timer24h = React.forwardRef(
  (
    { startTime, ...rest }: { startTime: MomentInput },
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const reRender = useReducer((x) => x + 1, 0)[1];
    const start = moment(startTime);
    const currentTime = moment();
    const diff = currentTime.diff(start);
    const duration = moment.duration(diff);
    const days = duration.days();
    const weeks = duration.weeks();
    const months = duration.months();

    useEffect(() => {
      if (days === 0) {
        const timer = setInterval(() => {
          reRender();
        }, 1000);
        return () => clearInterval(timer);
      } else {
        const timer = setInterval(
          () => {
            reRender();
          },
          1000 * 60 * 5,
        );
        return () => clearInterval(timer);
      }
    }, [days, reRender]);

    const time = `${format(duration.hours())}:${format(
      duration.minutes(),
    )}:${format(duration.seconds())}`;

    const { formatMessage } = useIntl();

    return (
      <span ref={ref} {...rest}>
        {months >= 1
          ? formatMessage(
              {
                defaultMessage:
                  "{months, number} {months, plural, one {month} other {months}}",
              },
              { months },
            )
          : weeks >= 1
            ? formatMessage(
                {
                  defaultMessage:
                    "{weeks, number} {weeks, plural, one {week} other {weeks}}",
                },
                { weeks },
              )
            : days >= 1
              ? formatMessage(
                  {
                    defaultMessage:
                      "{days, number} {days, plural, one {day} other {days}}",
                  },
                  { days },
                )
              : time === "00:00:00"
                ? "--:--:--"
                : time}
      </span>
    );
  },
);

function format(time: number) {
  if (time.toString().length < 2) {
    return `0${time}`;
  }
  return time;
}

export default Timer24h;
