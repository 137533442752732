import { getIn } from "final-form";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-final-form";

type IsEqual<T> = (a?: T, b?: T) => boolean;

type UseFieldValueProps<T> = {
  name: string;
  isEqual?: IsEqual<T>;
  transform?: (v?: any) => T;
};

const isThreeEqual = (a: any, b: any) => a === b;

export function notString<T>(value: T | "" | undefined) {
  return value === "" ? undefined : value;
}

export const useFieldValue = <T,>({
  name,
  isEqual = isThreeEqual,
  transform = notString,
}: {
  name: string;
  isEqual?: IsEqual<T>;
  transform?: (v?: any) => T;
}) => {
  const form = useForm("useTransformedFieldValue");
  const [value, setValue] = useState(() =>
    transform(getIn(form.getState().values, name)),
  );
  const isEqualRef = useRef(isEqual);

  useEffect(() => {
    isEqualRef.current = isEqual;
  }, [isEqual]);

  useEffect(
    () =>
      form.subscribe(
        (state) => {
          setValue((value) => {
            const newValue = transform(getIn(state.values, name));
            return isEqualRef.current(value, newValue) ? value : newValue;
          });
        },
        { values: true },
      ),
    [form, name, transform],
  );

  return value;
};

export const useSimpleFieldValue = <T,>({
  name,
  isEqual = isThreeEqual,
}: {
  name: string;
  isEqual?: IsEqual<T | "">;
}) => {
  const form = useForm("useSimpleFieldValue");
  const [value, setValue] = useState<T | undefined | "">(() =>
    getIn(form.getState().values, name),
  );
  const isEqualRef = useRef(isEqual);

  useEffect(() => {
    isEqualRef.current = isEqual;
  }, [isEqual]);

  useEffect(
    () =>
      form.subscribe(
        (state) => {
          setValue((value) => {
            const newValue = getIn(state.values, name);
            return isEqualRef.current(value, newValue) ? value : newValue;
          });
        },
        { values: true },
      ),
    [form, name],
  );

  return value;
};

export const FieldValue = <T,>({
  children,
  ...props
}: UseFieldValueProps<T> & {
  children: (props: { value: T }) => React.ReactNode;
}) => <>{children({ value: useFieldValue(props) })}</>;
