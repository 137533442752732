import "@fontsource/lato/index.css"; // required for the OneDayQuery, SevenDayQuery and ThirtyDayQuery icons
import { FullPageLayout, muiTheme } from "@mb-pro-ui/components";
import {
  ApiProvider,
  AuthProvider,
  ServiceHealthProvider,
  UserInfoProvider,
  useAuth,
} from "@mb-pro-ui/utils";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import DOMPurify from "dompurify";
import { SnackbarProvider } from "notistack";
import {
  ComponentType,
  LazyExoticComponent,
  PropsWithChildren,
  Suspense,
  lazy,
  useEffect,
} from "react";
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { AudioPlayerProvider, AutoplayPolicybar } from "./components/audio";
import { ErrorAlertManagerProvider } from "./components/audio/ErrorAlertManager";
import { AppHeader } from "./components/header/AppHeader";
import { AlarmAlertsProvider } from "./components/utils/useAlarmAlerts";
import { MeProvider } from "./hooks/useMe";
import LocaleProvider from "./locales/LocaleProvider";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import PasswordReset from "./pages/PasswordResetPage";

const ModuleLoadingFallback = () => (
  <Box display="flex" flex={1} bgcolor="background.default" />
);

const createLazyModule =
  (LazyModule: LazyExoticComponent<ComponentType<{}>>) => () => (
    <Suspense fallback={<ModuleLoadingFallback />}>
      <LazyModule />
    </Suspense>
  );

const modules = {
  gps: createLazyModule(
    lazy(() => import("./modules/VehicleMonitoringModule")),
  ),
  alarm: createLazyModule(lazy(() => import("./modules/AlarmModule"))),
  firesignal: createLazyModule(lazy(() => import("./pages/FiresignalPage"))),
  smartalarm: createLazyModule(lazy(() => import("./pages/SmartAlarmPage"))),
  settings: createLazyModule(
    lazy(() => import("./modules/UserSettingsModule")),
  ),
  patrol: createLazyModule(lazy(() => import("./modules/PatrolModule"))),
  lamp: createLazyModule(lazy(() => import("./modules/LampModule"))),
  measurements: createLazyModule(
    lazy(() => import("./modules/MeasurementsModule")),
  ),
  acs: createLazyModule(lazy(() => import("./modules/AccessControlModule"))),
};

DOMPurify.setConfig({ ADD_ATTR: ["target"] });

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  let { user } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location: from }) =>
        user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from } }} />
        )
      }
    />
  );
};

const BASE_URLS = {
  LOCAL: "http://localhost:7000",
  REMOTE: "/api",
  SA: "https://sa.monitoringbook.com/api",
  Dev: "https://dev.monitoringbook.com/api",
};

const Providers = ({ children }: PropsWithChildren<{}>) => (
  <ThemeProvider theme={muiTheme}>
    <CssBaseline />
    <UserInfoProvider>
      <ApiProvider baseUrl={BASE_URLS.REMOTE}>
        <AuthProvider>
          <ServiceHealthProvider>
            <SnackbarProvider hideIconVariant>{children}</SnackbarProvider>
          </ServiceHealthProvider>
        </AuthProvider>
      </ApiProvider>
    </UserInfoProvider>
  </ThemeProvider>
);

const AppContent = () => (
  <Switch>
    <Redirect
      from="/:url*(/+)"
      to={window.location.pathname.replace(/\/+$/, window.location.search)}
    />
    <Route path="/login">
      <LocaleProvider>
        <LoginPage />
      </LocaleProvider>
    </Route>
    <Route path="/password-reset">
      <LocaleProvider>
        <PasswordReset />
      </LocaleProvider>
    </Route>
    <PrivateRoute>
      <MeProvider>
        <AudioPlayerProvider>
          <ErrorAlertManagerProvider>
            <LocaleProvider connected>
              <FullPageLayout>
                <AlarmAlertsProvider>
                  <AppHeader />
                </AlarmAlertsProvider>
                <Switch>
                  <Route path="/home" component={HomePage} />
                  <Route path="/firesignal" component={modules.firesignal} />
                  <Route path="/smartalarm" component={modules.smartalarm} />
                  <Route path="/alarm" component={modules.alarm} />
                  <Route path="/user-settings" component={modules.settings} />
                  <Route
                    path="/measurements"
                    component={modules.measurements}
                  />
                  <Route path="/patrol" component={modules.patrol} />
                  <Route path="/lamp-control" component={modules.lamp} />
                  <Route path="/gps" component={modules.gps} />
                  <Route path="/acs" component={modules.acs} />
                  <Redirect to="/home" />
                </Switch>
                <AutoplayPolicybar />
              </FullPageLayout>
            </LocaleProvider>
          </ErrorAlertManagerProvider>
        </AudioPlayerProvider>
      </MeProvider>
    </PrivateRoute>
  </Switch>
);

const App = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (sessionStorage.getItem("pin") === "true") {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Router basename="/" getUserConfirmation={() => {}}>
      <Providers>
        <AppContent />
      </Providers>
    </Router>
  );
};

export default App;
