import { ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { useIntl } from "react-intl";
import useTimestampFormat from "../../locales/useTimestampFormat";
import CustomEditorButton from "../settings/email-templates/CustomEditorButton";
import { ToolbarComponentProps } from "../settings/email-templates/types";
import { Cdec, Customer, Intervention } from "./types";

export type InsertDataButtonProps = {
  customer?: Customer | null;
  intervention?: Intervention | null;
  cdecs?: Cdec[] | null;
};

export const InsertDataButton = ({
  customer,
  intervention,
  cdecs,
  ...rest
}: ToolbarComponentProps & InsertDataButtonProps) => {
  const { formatMessage } = useIntl();
  const { formatTimestamp } = useTimestampFormat();

  const name = customer?.name;
  const account = customer?.account;
  const address = customer?.address;
  const unitid = String(customer?.unitid);
  const phone = customer?.phone;
  const startTime = formatTimestamp(intervention?.["start-time"]);

  return (
    <CustomEditorButton
      buttonText={formatMessage({ defaultMessage: "Insert data" })}
      render={(insert, insertHtml) => (
        <MenuList dense sx={{ height: "100%" }}>
          {name && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insertHtml(name);
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Customer name" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          {account && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insert(account);
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Customer account" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          {address && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insert(address);
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Customer address" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          {unitid && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insert(unitid);
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Customer helios" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          {phone && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insert(phone);
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Customer phone number" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          {startTime && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insert(startTime);
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Intervention start time" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
          {cdecs && cdecs.length > 0 && (
            <MenuItem>
              <ListItemText
                onClick={() => {
                  insertHtml(
                    DOMPurify.sanitize(
                      "<ul>" +
                        cdecs?.reduce((acc, curr) => {
                          return (
                            (acc +=
                              "<li>" +
                              (curr["event-category"]?.[
                                "localized-description"
                              ] ||
                                formatMessage({
                                  defaultMessage: "Unknown event",
                                })) +
                              " | " +
                              curr["event-code"] +
                              " | " +
                              curr["localized-description"] +
                              " | " +
                              formatTimestamp(curr.arrived)) + "</li>"
                          );
                        }, "") +
                        "</ul>",
                    ),
                  );
                }}
              >
                <Typography noWrap variant="body2">
                  {formatMessage({ defaultMessage: "Intervention events" })}
                </Typography>
              </ListItemText>
            </MenuItem>
          )}
        </MenuList>
      )}
      {...rest}
    />
  );
};
