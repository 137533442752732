export const intToColor = (color?: number | null) =>
  typeof color === "number"
    ? `rgba(${(color >> 16) & 0xff},${(color >> 8) & 0xff},${color & 0xff},${
        ((color >> 24) & 0xff) / 255
      })`
    : "rgba(0, 0, 0, 1)";

export const colorToInt = (r: number, g: number, b: number, a: number = 1) =>
  ((Math.round(a * 255) & 0xff) << 24) |
  ((r & 0xff) << 16) |
  ((g & 0xff) << 8) |
  (b & 0xff);

export const hexToColor = (hex?: string | null) => {
  if (!hex) {
    return { r: 0, g: 0, b: 0 };
  }
  hex = hex.substring(hex.length % 3);
  const w = hex.length / 3;
  return {
    r: parseInt(hex.substring(0, w), 16),
    g: parseInt(hex.substring(w, 2 * w), 16),
    b: parseInt(hex.substring(2 * w), 16),
  };
};

export const colorToHex = (
  rgb?: {
    r?: number | null;
    g?: number | null;
    b?: number | null;
  } | null,
) =>
  `#${(rgb?.r ?? 0).toString(16).padStart(2, "0")}${(rgb?.g ?? 0)
    .toString(16)
    .padStart(2, "0")}${(rgb?.b ?? 0).toString(16).padStart(2, "0")}`;

export const intToHex = (int: number) =>
  colorToHex({
    r: (int >> 16) & 0xff,
    g: (int >> 8) & 0xff,
    b: int & 0xff,
  });

export const hexToInt = (hex?: string | null) => {
  const { r, g, b } = hexToColor(hex);
  return 0xff000000 | ((r & 0xff) << 16) | ((g & 0xff) << 8) | (b & 0xff);
};
