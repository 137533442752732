import SvgIcon from "@mui/icons-material/Memory";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as SmartAlarmIcon } from "../../icons/module/SmartAlarm.svg";
import { SmartAlarmRoutes } from "../types";

const useSmartAlarmMenuLinks = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        icon: <SvgIcon component={SmartAlarmIcon} />,
        href: SmartAlarmRoutes.SmartAlarms,
        label: formatMessage({
          defaultMessage: "Smart Alarm",
          description: "Main menu",
        }),
      },
    ],
    [formatMessage],
  );
};

export default useSmartAlarmMenuLinks;
