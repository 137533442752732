import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import SensorsIcon from "@mui/icons-material/Sensors";
import TimelineIcon from "@mui/icons-material/Timeline";
import { SvgIcon } from "@mui/material";
import get from "lodash/get";
import { useIntl } from "react-intl";
import { Module, ModuleID } from "../components/types";
import { ReactComponent as ConsumptionIcon } from "../icons/module/Consumption.svg";
import { ReactComponent as DispatcherIcon } from "../icons/module/Dispatcher.svg";
import { ReactComponent as IndoorPositioningIcon } from "../icons/module/IndoorPositioning.svg";
import { ReactComponent as PatrolIcon } from "../icons/module/Patrol.svg";
import { ReactComponent as SmartAlarmIcon } from "../icons/module/SmartAlarm.svg";
import { ReactComponent as StockIcon } from "../icons/module/Stock.svg";
import { ReactComponent as TelemedicinaIcon } from "../icons/module/Telemedicina.svg";
import { ReactComponent as VehicleIcon } from "../icons/module/Vehicle.svg";
import { ReactComponent as VideoIcon } from "../icons/module/Video.svg";
import useMe, { User } from "./useMe";

export interface ImageProps {
  width: string;
  height: string;
}

export const isModuleHidden = (
  user: User | undefined,
  path: string,
  defaultEnabled = true,
) =>
  !get(
    user,
    `view-flags.${path}`,
    get(user, `org.view-flags.${path}`, defaultEnabled),
  );

function useModules() {
  const { formatMessage } = useIntl();

  const { data: user, isLoading, error } = useMe();

  const modules: Module[] = [
    {
      id: ModuleID.Dispatcher,
      name: formatMessage({
        defaultMessage: "Alarm Monitoring",
        description: "Module name Alarm Monitoring",
      }),
      path: "/alarm/operator",
      icon: (props) => <SvgIcon component={DispatcherIcon} {...props} />,
      active: !!user?.["alarm-operator"],
      info: "Alarm Monitoring module",
    },
    {
      id: ModuleID.Patrol,
      name: formatMessage({
        defaultMessage: "Patrol Monitoring",
        description: "Module name Patrol Monitoring",
      }),
      icon: (props) => (
        <SvgIcon component={PatrolIcon} viewBox="-2 0 22 24" {...props} />
      ),
      path: "/patrol",
      active: !!user?.["alarm-operator"],
      info: formatMessage({
        defaultMessage: "Patrol Monitoring",
        description: "Module name Patrol Monitoring",
      }),
    },
    {
      id: ModuleID.Firesignal,
      name: formatMessage({
        defaultMessage: "Firesignal",
        description: "Module name",
      }),
      icon: (props) => (
        <SvgIcon component={LocalFireDepartmentIcon} {...props} />
      ),
      path: "/firesignal",
      active: !!user?.["alarm-operator"],
      info: "Localized info about Firesignal",
    },
    {
      id: ModuleID.SmartAlarm,
      name: formatMessage({
        defaultMessage: "SmartAlarm",
        description: "Module name",
      }),
      icon: (props) => <SvgIcon component={SmartAlarmIcon} {...props} />,
      path: "/smartalarm",
      active: !!user?.["alarm-operator"],
      info: "Localized info about SmartAlarm",
    },
    {
      id: ModuleID.Vehicle,
      name: formatMessage({
        defaultMessage: "Vehicle Monitoring",
        description: "Module name Vehicle Monitoring",
      }),
      icon: (props) => <SvgIcon component={VehicleIcon} {...props} />,
      path: "/gps",
      active: !!user?.["gps-user"],
      info: formatMessage({
        defaultMessage: "Vehicle Monitoring",
        description: "Module name Vehicle Monitoring",
      }),
    },
    {
      id: ModuleID.IndoorPositioning,
      name: formatMessage({
        defaultMessage: "Indoor Positioning",
        description: "Module name Indoor Positioning",
      }),
      icon: (props) => <SvgIcon component={IndoorPositioningIcon} {...props} />,
      path: "/indoorPositioning",
      active: false,
      info: formatMessage({
        defaultMessage: "Indoor Positioning",
        description: "Module name Indoor Positioning",
      }),
    },
    {
      id: ModuleID.Video,
      name: formatMessage({
        defaultMessage: "Remote Video Controll",
        description: "Module name Remote Video Controll",
      }),
      icon: (props) => <SvgIcon component={VideoIcon} {...props} />,
      path: "/video",
      active: false,
      info: formatMessage({
        defaultMessage: "Remote Video Controll",
        description: "Module name Remote Video Controll",
      }),
    },
    {
      id: ModuleID.Telemedicina,
      name: formatMessage({
        defaultMessage: "Telemedicina",
        description: "Module name Telemedicina",
      }),
      icon: (props) => <SvgIcon component={TelemedicinaIcon} {...props} />,
      path: "/telemedicina",
      active: false,
      info: formatMessage({
        defaultMessage: "Telemedicina",
        description: "Module name Telemedicina",
      }),
    },
    {
      id: ModuleID.Measurements,
      name: formatMessage({
        defaultMessage: "Measurements",
        description: "Module name Measurements",
      }),
      icon: (props) => <SvgIcon component={TimelineIcon} {...props} />,
      path: "/measurements",
      active: !!user?.["alarm-operator"],
      info: formatMessage({
        defaultMessage: "Measurements",
        description: "Module name Measurements",
      }),
    },
    {
      id: ModuleID.AccessControl,
      name: formatMessage({
        defaultMessage: "Access Control",
      }),
      icon: (props) => <SvgIcon component={SensorsIcon} {...props} />,
      path: "/acs",
      active: !!user?.["alarm-operator"],
      info: formatMessage({
        defaultMessage: "Access Control",
      }),
    },
    {
      id: ModuleID.Consumption,
      name: formatMessage({
        defaultMessage: "Consumption Monitoring",
        description: "Module name Consumption Monitoring",
      }),
      icon: (props) => <SvgIcon component={ConsumptionIcon} {...props} />,
      path: "/consumption",
      active: false,
      info: formatMessage({
        defaultMessage: "Consumption Monitoring",
        description: "Module name Consumption Monitoring",
      }),
    },
    {
      id: ModuleID.Stock,
      name: formatMessage({
        defaultMessage: "Stock Monitoring",
        description: "Module name Stock Monitoring",
      }),
      icon: (props) => <SvgIcon component={StockIcon} {...props} />,
      path: "/alarm/stock-monitoring",
      active: false,
      info: formatMessage({
        defaultMessage: "Stock Monitoring",
        description: "Module name Stock Monitoring",
      }),
    },
    {
      id: ModuleID.Lamp,
      name: formatMessage({
        defaultMessage: "Lighting Control",
      }),
      icon: (props) => <SvgIcon component={EmojiObjectsIcon} {...props} />,
      path: "/lamp-control",
      active: !!user?.["alarm-operator"],
      info: formatMessage({
        defaultMessage: "Lighting Control",
      }),
    },
  ];

  modules.sort((a, b) => {
    let h1 = a.active ? 1 : 0;
    let h2 = b.active ? 1 : 0;
    if (h1 < h2) return 1;
    if (h2 < h1) return -1;
    return 0;
  });

  const activeModules = modules.filter(
    ({ active, hidden }) => active && !hidden,
  );

  const inactiveModules = modules.filter(
    ({ active, hidden }) => !active && !hidden,
  );

  return { activeModules, inactiveModules, isLoading, error };
}

export default useModules;
