import moment from "moment";
import { useIntl } from "react-intl";

export type Intervals = { label: string; interval: [string, string] }[];

const useIntervals = () => {
  const { formatMessage } = useIntl();

  const intervals = [
    {
      label: formatMessage({
        defaultMessage: "Last hour",
        description: "Date range filter interval select last hour option label",
      }),
      interval: [moment().subtract(1, "hour").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "Today",
        description: "Date range filter interval select today option label",
      }),
      interval: [moment().startOf("day").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "Last day",
        description: "Date range filter interval select last day option label",
      }),
      interval: [moment().subtract(1, "day").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "This Week",
        description: "Date range filter interval select this week option label",
      }),
      interval: [moment().startOf("week").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "Last Week",
        description: "Date range filter interval select last week option label",
      }),
      interval: [moment().subtract(1, "week").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "Last 2 weeks",
        description:
          "Date range filter interval select last 2 weeks option label",
      }),
      interval: [moment().subtract(2, "week").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "This month",
        description:
          "Date range filter interval select this month option label",
      }),
      interval: [moment().startOf("month").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "Last month",
        description:
          "Date range filter interval select last month option label",
      }),
      interval: [moment().subtract(1, "month").format(), moment().format()],
    },
    {
      label: formatMessage({
        defaultMessage: "Last 3 months",
        description:
          "Date range filter interval select last 3 months option label",
      }),
      interval: [moment().subtract(3, "month").format(), moment().format()],
    },
  ];

  return intervals;
};

export default useIntervals;
