import { SvgIcon } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as CustomerManagerMenuIcon } from "../../icons/CustomerManagerMenu.svg";
import { PatrolRoutes } from "../types";

const useMeasMenuLinks = () => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => [
      {
        icon: (
          <SvgIcon component={CustomerManagerMenuIcon} viewBox="0 0 50 50" />
        ),
        href: PatrolRoutes.Customers,
        label: formatMessage({ defaultMessage: "Customers" }),
      },
    ],
    [formatMessage],
  );
};

export default useMeasMenuLinks;
