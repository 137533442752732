import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  alpha,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Module, ModuleID } from "../../components/types";
import useModules from "../../hooks/useModules";

export interface ModuleMenuProps {
  currentModuleID?: ModuleID;
}

const ModuleItem = React.forwardRef<HTMLDivElement, { module: Module }>(
  ({ module }: { module: Module }, ref) => {
    return (
      <NavLink to={module.path ?? "/"} style={{ textDecoration: "none" }}>
        <MenuItem
          ref={ref}
          dense
          disabled={!module.active}
          component="div"
          color="primary"
        >
          {module.id.valueOf() === ModuleID.Ozone.valueOf() ? (
            <ListItemIcon
              sx={{
                minWidth: "40px",
                fontSize: "24px",
              }}
            >
              {module.img && module.img("24px", "24px")}
            </ListItemIcon>
          ) : (
            <ListItemIcon
              sx={{
                minWidth: "40px",
                fontSize: "24px",
              }}
            >
              {module.icon &&
                module.icon({ fontSize: "inherit", color: "primary" })}
            </ListItemIcon>
          )}
          <ListItemText primary={module.name} />
        </MenuItem>
      </NavLink>
    );
  },
);

export const ModuleMenu = ({ currentModuleID }: ModuleMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { activeModules, inactiveModules } = useModules();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentModule = activeModules.find(
    (module) => module.id.valueOf() === currentModuleID?.valueOf(),
  );

  const selectableModules = activeModules.filter(
    (module) => module.id.valueOf() !== currentModuleID?.valueOf(),
  );

  return (
    <>
      <Box sx={{ alignSelf: "center", mr: 2 }}>
        {currentModuleID ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              "&:hover": {
                backgroundColor: (theme) => alpha(theme.palette.grey[100], 1),
              },
            }}
            startIcon={
              currentModule?.icon && currentModule.icon({ color: "primary" })
            }
            endIcon={<ArrowDropDownIcon color="primary" />}
            onClick={handleClick}
            size="medium"
          >
            <Typography color="primary">{currentModule?.name}</Typography>
          </Button>
        ) : (
          <IconButton onClick={handleClick}>
            <AppsOutlinedIcon />
          </IconButton>
        )}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiList-padding": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {selectableModules.map((module) => (
          <ModuleItem module={module} key={module.id} />
        ))}
        {inactiveModules.map((module) => (
          <ModuleItem module={module} key={module.id} />
        ))}
      </Menu>
    </>
  );
};

export default ModuleMenu;
