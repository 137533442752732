import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Snackbar } from "@mui/material";
import { useIntl } from "react-intl";
import { useAudio } from "./AudioPlayerProvider";

const AutoplayPolicybar = () => {
  const { isUnlocked } = useAudio();
  const { formatMessage } = useIntl();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      sx={{ "& > div": { backgroundColor: "error.main" } }}
      open={isUnlocked === false}
      action={<VolumeOffIcon />}
      message={formatMessage({
        defaultMessage: "Click anywhere to enable sound",
        description: "Autoplay policy snackbar üzenet",
      })}
    />
  );
};

export default AutoplayPolicybar;
