import { styled } from "@mui/material";

const StyledForm = styled("form")(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export default StyledForm;
