import { Button, TextField } from "@mb-pro-ui/components";
import { useApi } from "@mb-pro-ui/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { useLocale } from "../locales/LocaleProvider";

const StyledForm = styled("form")(({ theme }) => ({
  width: "70%",
  marginTop: "2rem",
  [theme.breakpoints.down("sm")]: { width: "100%" },
}));

const NewPasswordInput = ({
  value,
  setValue,
  label,
  isError,
  errorMessage,
}: {
  value: string;
  setValue: (v: string) => void;
  label: string;
  isError?: boolean;
  errorMessage?: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      sx={{ margin: "0.2rem", width: "100%" }}
      type={showPassword ? "text" : "password"}
      value={value}
      label={label}
      onChange={(e) => setValue(e.target.value)}
      required
      autoCapitalize="off"
      inputProps={{ minLength: 8 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((flag) => !flag)}
              data-testid="toggle-visibility"
              size="large"
            >
              {showPassword ? (
                <VisibilityOffIcon color="primary" />
              ) : (
                <VisibilityIcon color="primary" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={isError}
      helperText={errorMessage ?? "\u00a0"}
      autoComplete="new-password"
    />
  );
};

const LocalePicker = ({
  locale,
  locales,
  setLocale,
}: {
  locale: string;
  locales: string[];
  setLocale: (l: string) => void;
}) => (
  <Box
    sx={(theme) => ({
      alignSelf: "flex-end",
      color: theme.palette.text.secondary,
      position: "absolute",
      top: "30px",
      right: "30px",
      [theme.breakpoints.down("sm")]: { top: "10px", right: "10px" },
    })}
  >
    {locales.map((lang) => (
      <ButtonBase
        sx={{
          "&:not(:first-of-type):before": {
            content: '"/"',
            paddingLeft: "0.2rem",
            paddingRight: "0.2rem",
            textDecoration: "initial",
          },
        }}
        key={lang}
        onClick={() => setLocale(lang)}
        disabled={locale === lang}
      >
        <Typography sx={[locale === lang && { fontWeight: "bold" }]}>
          {lang}
        </Typography>
      </ButtonBase>
    ))}
  </Box>
);

const PasswordReset = () => {
  const { formatMessage } = useIntl();
  const { locale, locales, setLocale } = useLocale();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRe, setNewPasswordRe] = useState("");

  const token = useMemo(
    () => new URLSearchParams(window.location.search).get("token"),
    [],
  );

  useEffect(() => {
    document.title = formatMessage({
      defaultMessage: "Password Reset - Monitoringbook Pro",
    });
  }, [formatMessage]);

  const api = useApi();

  const { error, mutate, isLoading, isSuccess, reset } = useMutation({
    mutationFn: ({ newPassword }: { newPassword: string }) =>
      api("/auth/password-reset", {
        method: "POST",
        body: JSON.stringify({ password: newPassword, token }),
        headers: { "Content-Type": "application/vnd.api+json" },
      }),
  });

  const getUserInfo = useCallback(
    () =>
      api<{ username: string; language: string }>(
        `/auth/password-reset-info?token=${token}`,
      ),
    [api, token],
  );

  const info = useQuery([token], getUserInfo, {
    enabled: !!token,
  });

  const language = info.data?.language;

  useEffect(() => {
    if (language) {
      setLocale(language);
    }
  }, [setLocale, language]);

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      mutate({ newPassword });
    },
    [mutate, newPassword],
  );

  const errorMessage = useMemo(() => {
    if (error) {
      return formatMessage({
        defaultMessage: "Failed to change the password",
      });
    } else {
      return "\u00a0";
    }
  }, [error, formatMessage]);

  useEffect(() => {
    reset();
  }, [reset, newPassword, newPasswordRe]);

  const newPasswordError = useMemo(() => {
    if (newPassword && newPassword.length < 8) {
      return formatMessage({
        defaultMessage: "Password must be at least 8 characters long",
      });
    }
  }, [formatMessage, newPassword]);

  const newPasswordReError = useMemo(() => {
    if (newPasswordRe && newPasswordRe.length < 8) {
      return formatMessage({
        defaultMessage: "Password must be at least 8 characters long",
      });
    } else if (newPassword && newPasswordRe && newPassword !== newPasswordRe) {
      return formatMessage({
        defaultMessage: "Passwords do not match",
      });
    }
  }, [formatMessage, newPassword, newPasswordRe]);

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Container
        maxWidth="md"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          padding: "5%",
          position: "relative",
          [theme.breakpoints.up("md")]: { borderRadius: "20px" },
        })}
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <LocalePicker
            locale={locale}
            locales={locales}
            setLocale={setLocale}
          />
          <Typography variant="h1">
            <FormattedMessage defaultMessage="Password Reset" />
          </Typography>
          {!token || info.isError ? (
            <>
              <Typography sx={{ margin: "4rem" }} variant="subtitle1">
                <FormattedMessage defaultMessage="This URL does not contain a valid password reset token" />
              </Typography>
              <Button sx={{ marginTop: "4rem" }} size="large" href="/login">
                <FormattedMessage defaultMessage="Go to login page" />
              </Button>
            </>
          ) : isSuccess ? (
            <>
              <Typography sx={{ margin: "4rem" }} variant="subtitle1">
                <FormattedMessage defaultMessage="Password changed successfully" />
              </Typography>
              <Button sx={{ marginTop: "4rem" }} size="large" href="/login">
                <FormattedMessage defaultMessage="Go to login page" />
              </Button>
            </>
          ) : (
            <>
              <Hidden smDown>
                <Typography sx={{ marginBottom: "4rem" }} variant="subtitle1">
                  <FormattedMessage defaultMessage="Enter your new password below" />
                </Typography>
              </Hidden>
              <Typography color="error" variant="caption">
                {errorMessage}
              </Typography>
              <StyledForm autoComplete="off" onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                >
                  {info.data ? (
                    <input
                      type="text"
                      autoComplete="username"
                      value={info.data?.username}
                      hidden
                      readOnly
                    />
                  ) : null}
                  <NewPasswordInput
                    value={newPassword}
                    setValue={setNewPassword}
                    label={formatMessage({
                      defaultMessage: "New password",
                    })}
                    isError={!!newPasswordError}
                    errorMessage={newPasswordError}
                  />
                  <NewPasswordInput
                    value={newPasswordRe}
                    setValue={setNewPasswordRe}
                    label={formatMessage({
                      defaultMessage: "Confirm new password",
                    })}
                    isError={!!newPasswordReError}
                    errorMessage={newPasswordReError}
                  />
                  {isLoading || isSuccess || info.isLoading ? (
                    <CircularProgress
                      data-testid="loading-spinner"
                      sx={{ marginTop: "4rem" }}
                      size={40.5} // height of Button size="large"
                    />
                  ) : (
                    <Button
                      sx={{
                        marginTop: "4rem",
                        paddingRight: "4.5rem",
                        paddingLeft: "4.5rem",
                        fontWeight: (theme) => theme.typography.fontWeightLight,
                      }}
                      size="large"
                      type="submit"
                      disabled={
                        !newPassword ||
                        !newPasswordRe ||
                        !!newPasswordError ||
                        !!newPasswordReError
                      }
                    >
                      <FormattedMessage defaultMessage="Save new password" />
                    </Button>
                  )}
                </Grid>
              </StyledForm>
            </>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default PasswordReset;
