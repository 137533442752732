import { Button } from "@mb-pro-ui/components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
});

type BulkInterventionModalProps = {
  open: boolean;
  onClose: () => void;
  interventionCdecs: {
    id: string;
    customer: { id: string; account: string; name: string | null } | null;
  }[];
  interventionNeeded: number | undefined;
};

export const BulkInterventionModal = ({
  open,
  onClose: handleClose,
  interventionCdecs,
}: BulkInterventionModalProps) => {
  const handleSendClick = () => {
    handleClose();
  };

  const lastCdecIdAndCustomerNameByAccount = interventionCdecs.reduce(
    (map, cdec) => {
      const customerName = cdec.customer?.name;
      const customerAccount = cdec.customer?.account;

      if (customerAccount) {
        if (!map.has(customerAccount)) {
          map.set(customerAccount, {
            customerName: customerName && customerName,
            cdecId: cdec.id,
          });
        }
      }
      return map;
    },
    new Map<
      string,
      { customerName: string | undefined | null; cdecId: string }
    >(),
  );

  const lastCdecIds: string[] = [];

  [...lastCdecIdAndCustomerNameByAccount.values()].forEach((idAndName) => {
    lastCdecIds.push(idAndName.cdecId);
  });

  const accounts = [...lastCdecIdAndCustomerNameByAccount.keys()];

  const half = Math.ceil(accounts.length / 2);
  const firstHalf = accounts.slice(0, half);
  const secondHalf = accounts.slice(half);

  return (
    <Dialog open={open} onClose={handleClose} disableScrollLock color="warning">
      <DialogTitle sx={{ backgroundColor: "warning.main" }}>
        <ListItemText
          sx={{ color: (theme) => theme.palette.primary.contrastText }}
          primaryTypographyProps={{ style: { color: "inherit" } }}
          primary={
            <FormattedMessage
              defaultMessage="Start/Update {count} intervention for the following customers"
              description="Intézkedés tömeges megnyitása popup alcím"
              values={{
                count: lastCdecIdAndCustomerNameByAccount.size,
              }}
            />
          }
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid item>
              <List dense disablePadding>
                {firstHalf.map((account) => (
                  <ListItem
                    key={account}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ListItemText>{`${
                      lastCdecIdAndCustomerNameByAccount.get(account)
                        ?.customerName
                    } (${account})`}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item>
              <List dense disablePadding>
                {secondHalf.map((account) => (
                  <ListItem
                    key={account}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ListItemText>{`${
                      lastCdecIdAndCustomerNameByAccount.get(account)
                        ?.customerName
                    } (${account})`}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button mode="secondary" onClick={handleClose} color="warning">
          <FormattedMessage
            defaultMessage="Cancel"
            description="Intézkedés tömeges megnyitása popup mégse gomb"
          />
        </Button>
        <Button onClick={handleSendClick} color="warning">
          <StyledLink
            to={`/alarm/interventions/create?start-events=${lastCdecIds.join(
              ",",
            )}`}
          >
            <FormattedMessage
              defaultMessage="Intervention"
              description="Intézkedés tömeges megnyitása popup 'igen' gomb"
            />
          </StyledLink>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkInterventionModal;
