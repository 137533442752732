import useDebounce from "@mb-pro-ui/components/utils/useDebounce";
import {
  Box,
  IconButton,
  Popover,
  Slider,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as VolumeDown } from "../../icons/VolumeDown.svg";
import { ReactComponent as VolumeOffIcon } from "../../icons/VolumeOff.svg";
import { ReactComponent as VolumeUpIcon } from "../../icons/VolumeUp.svg";
import { useAudio } from "./AudioPlayerProvider";

const volMin = 0;
const volMax = 1;

const normalise = (value: number) =>
  ((value - volMin) * 100) / (volMax - volMin);

const denormalise = (percentage: number) =>
  (percentage * (volMax - volMin) + volMin) / 100;

export const VolumeButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formatMessage } = useIntl();
  const { isUnlocked, volume, setVolume } = useAudio();

  const [vol, setVol] = useState(volume);
  const debouncedValue = useDebounce(String(vol), 200);

  useEffect(() => {
    setVolume(Number(debouncedValue));
  }, [debouncedValue, setVolume]);

  const open = Boolean(anchorEl);
  let volumeIcon = <SvgIcon component={VolumeUpIcon} />;

  if (isUnlocked === false) {
    volumeIcon = (
      <SvgIcon
        component={VolumeOffIcon}
        color="error"
        sx={{
          "& path": { fill: "inherit" },
        }}
      />
    );
  } else if (volume === volMin) {
    volumeIcon = <SvgIcon component={VolumeOffIcon} />;
  } else if (volume < volMax) {
    volumeIcon = <SvgIcon component={VolumeDown} />;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setVol(denormalise(newValue as number));
  };

  const volumeAsPercentage = normalise(vol);

  return (
    <>
      <Tooltip
        title={formatMessage({
          defaultMessage: "Volume",
          description: "Hangerő gomb tooltip felirat",
        })}
      >
        <IconButton
          onClick={handleClick}
          size="large"
          sx={{ alignSelf: "center" }}
        >
          {volumeIcon}
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableScrollLock
      >
        <Box
          width="200px"
          height="40px"
          p="5px 20px"
          display="flex"
          alignItems="center"
          sx={{
            overflow: "hidden",
          }}
        >
          <Slider
            value={volumeAsPercentage}
            onChange={handleSliderChange}
            size="small"
          />
        </Box>
      </Popover>
    </>
  );
};

export default VolumeButton;
