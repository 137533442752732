import { Button } from "@mb-pro-ui/components";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, CircularProgress, SxProps } from "@mui/material";
import { ReactNode } from "react";
import { useIntl } from "react-intl";

interface UpdateFormActionsProps {
  isLoading?: boolean;
  isDeleting?: boolean;
  isSaving?: boolean;
  onDelete?: () => void;
  onReset?: () => void;
  onSave?: () => void;
  disabledDelete?: boolean;
  disabledReset?: boolean;
  disabledSave?: boolean;
  prefix?: ReactNode;
  postfix?: ReactNode;
  sx?: SxProps;
  fixed?: boolean;
  scrollbarSize?: number;
}

const UpdateFormActions = ({
  isLoading,
  isDeleting,
  isSaving,
  onDelete,
  onReset,
  onSave,
  disabledDelete,
  disabledReset,
  disabledSave,
  prefix,
  postfix,
  sx,
  fixed = false,
  scrollbarSize,
}: UpdateFormActionsProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Box
        sx={[
          {
            display: "flex",
            justifyContent: "flex-end",
            m: 1,
            mt: 3,
          },
          fixed && {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: `calc(100% - ${scrollbarSize}px)`,
            backgroundColor: (theme: any) => theme.palette.background.default,
            m: 0,
            p: 1,
            justifyContent: "space-between",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {isLoading ? (
          <CircularProgress
            sx={{ color: "white", marginRight: "5px" }}
            size="17px"
          />
        ) : (
          <>
            {prefix}
            {onDelete && (
              <Button
                startIcon={
                  isDeleting ? (
                    <CircularProgress
                      size={16}
                      sx={{
                        color: "error.contrastText",
                      }}
                    />
                  ) : (
                    <DeleteOutlineIcon />
                  )
                }
                onClick={onDelete}
                size="small"
                disabled={disabledDelete}
                color="error"
              >
                {formatMessage({ defaultMessage: "Delete" })}
              </Button>
            )}
            <Box sx={{ flex: 1 }}></Box>
            {onReset && (
              <Button
                startIcon={<ReplayIcon />}
                variant="outlined"
                onClick={onReset}
                size="small"
                disabled={disabledReset}
                sx={{ mx: 2 }}
              >
                {formatMessage({ defaultMessage: "Reset" })}
              </Button>
            )}
            {onSave && (
              <Button
                startIcon={
                  isSaving ? (
                    <CircularProgress
                      size={16}
                      sx={{
                        color: "primary.contrastText",
                      }}
                    />
                  ) : (
                    <SaveOutlinedIcon />
                  )
                }
                onClick={onSave}
                size="small"
                disabled={disabledSave}
              >
                {formatMessage({ defaultMessage: "Save" })}
              </Button>
            )}
            {postfix}
          </>
        )}
      </Box>
    </>
  );
};

export default UpdateFormActions;
