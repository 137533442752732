import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { UserSettingsRoutes } from "../components/types";
import useMe from "./useMe";

const useUserSettingsMenuLinks = () => {
  const { formatMessage } = useIntl();
  const { data: user } = useMe();

  return useMemo(
    () => [
      {
        icon: <PersonIcon />,
        href: UserSettingsRoutes.Profile,
        label: formatMessage({
          defaultMessage: "Profile",
        }),
      },
      ...(user?.admin
        ? [
            {
              icon: <PeopleIcon />,
              href: UserSettingsRoutes.Users,
              label: formatMessage({
                defaultMessage: "Users",
              }),
            },
          ]
        : []),
      ...((user?.["alarm-operator"]?.active &&
        user?.["alarm-operator"]?.admin) ||
      user?.admin
        ? [
            {
              icon: <BusinessIcon />,
              href: UserSettingsRoutes.CompanyInfo,
              label: formatMessage({
                defaultMessage: "Company Info",
              }),
            },
          ]
        : []),
    ],
    [formatMessage, user],
  );
};

export default useUserSettingsMenuLinks;
