import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import SvgIcon from "@mui/icons-material/Memory";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { FiresignalRoutes } from "../types";

const useFiresignalMenuLinks = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        icon: (
          <SvgIcon component={LocalFireDepartmentIcon} viewBox="0 0 25 25" />
        ),
        href: FiresignalRoutes.Firesignals,
        label: formatMessage({
          defaultMessage: "Firesignals",
          description: "Main menu",
        }),
      },
    ],
    [formatMessage],
  );
};

export default useFiresignalMenuLinks;
