import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import errorAlert from "../../sound/sound-serious-error.wav";
import { useAudio } from "./AudioPlayerProvider";

const errorAlertDelay = 30000;

export interface ErrorAlertManager {
  setErrorState: (id: string, state: boolean) => void;
  clearErrorStates: () => void;

  errorAlertCountdown: number;
  isAnyError: boolean;
}

const ErrorAlertManagerContext = React.createContext<
  ErrorAlertManager | undefined
>(undefined);

export function ErrorAlertManagerProvider({ children }: PropsWithChildren<{}>) {
  const [errorAlertRegister, setErrorAlertRegister] = useState<string[]>(
    () => [],
  );

  const isAnyError = useMemo(() => {
    return errorAlertRegister.length > 0;
  }, [errorAlertRegister]);

  const [errorAlertCountdown, setErrorAlertCountdown] = useState(
    errorAlertDelay / 1000,
  );

  const { startLoop, stopLoops, preloadAudio } = useAudio();

  useEffect(() => {
    preloadAudio(errorAlert);
  }, [preloadAudio]);

  useEffect(() => {
    if (!isAnyError) {
      return;
    }

    const intervalID = setInterval(() => {
      setErrorAlertCountdown((prev) => (prev > 0 ? prev - 1 : prev));
    }, 1000);

    return () => {
      clearInterval(intervalID);
      setErrorAlertCountdown(errorAlertDelay / 1000);
    };
  }, [isAnyError]);

  const isAlertPlaying = useMemo(() => {
    return errorAlertCountdown === 0 && isAnyError;
  }, [errorAlertCountdown, isAnyError]);

  useEffect(() => {
    if (isAlertPlaying) {
      startLoop(errorAlert, "error-alert");
    } else {
      stopLoops("error-alert", errorAlert);
    }
  }, [isAlertPlaying, startLoop, stopLoops]);

  const setErrorState = useCallback((id: string, state: boolean) => {
    setErrorAlertRegister((prev) => {
      if (state && !prev.includes(id)) {
        return [...prev, id];
      } else {
        return prev.filter((i) => i !== id);
      }
    });
  }, []);

  const clearErrorStates = useCallback(() => {
    setErrorAlertRegister([]);
  }, []);

  return (
    <ErrorAlertManagerContext.Provider
      value={useMemo(
        () => ({
          isAnyError,
          setErrorState,
          errorAlertCountdown,
          clearErrorStates,
        }),
        [isAnyError, errorAlertCountdown, setErrorState, clearErrorStates],
      )}
    >
      {children}
    </ErrorAlertManagerContext.Provider>
  );
}

export function useErrorAlertManager() {
  const errorAlertManagerContext = React.useContext(ErrorAlertManagerContext);
  if (errorAlertManagerContext === undefined) {
    throw new Error(
      "useErrorAlertManager must be used within an ErrorAlertManagerProvider",
    );
  }
  return errorAlertManagerContext;
}
