import { JsonapiErrorMessage } from "@mb-pro-ui/components";
import { isHttpError, isJsonapiHttpError } from "@mb-pro-ui/utils";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";
import backendMessages from "../locales/backendMessages";

export const getErrorMessage = (error: JsonapiError) => {
  if (isJsonapiHttpError(error) && error.data.errors?.[0]) {
    return (
      <JsonapiErrorMessage
        error={error.data.errors[0]}
        backendMessages={backendMessages}
      />
    );
  } else if (isHttpError(error)) {
    return <div>{`${error.status} ${error.message}`}</div>;
  } else {
    return <div>{error.message}</div>;
  }
};

export const JsonApiErrorSnackbar = ({
  onClose: handleSnackbarClose,
  error,
}: {
  onClose: () => void;
  error: JsonapiError | null;
}) => (
  <Snackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    open={!!error}
    autoHideDuration={5000}
    message={error && getErrorMessage(error)}
    onClose={handleSnackbarClose}
    action={
      <>
        <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    }
    sx={{
      "& > div": {
        backgroundColor: "error.main",
      },
    }}
  />
);

// const useErrorHandler = () => {
//   const getErrorMessage = (error: JsonapiError) => {
//     // let message = (
// <FormattedMessage
//   defaultMessage="Something went wrong"
//   description="Error handler default error message"
// />
//     // );
//     if (isJsonapiHttpError(error)) {
//       return <JsonapiErrorMessage error={error.data.errors[0]} />;
//     } else if (isHttpError(error)) {
//       return <div>{`${error.status} ${error.message}`}</div>;
//     } else {
//       return <div>{error.message}</div>;
//     }
//   };

//   return { getErrorMessage };
// };

// export default useErrorHandler;
