import { Page } from "@mb-pro-ui/components";
import { utils } from "@mb-pro-ui/utils";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Module, ModuleID } from "../components/types";
import { useModules } from "../hooks";
import { getErrorMessage } from "../hooks/useErrorHandler";

const ModuleCards = ({ modules }: { modules: Module[] }) => (
  <Grid
    container
    spacing={4}
    alignItems="center"
    justifyContent="flex-start"
    alignContent="center"
  >
    {modules.map((module) => (
      <Grid
        key={module.id}
        item
        xs={12}
        sm={6}
        md={3}
        container
        justifyContent="center"
      >
        {(() => {
          const button = (
            <Button
              {...(module.active
                ? {
                    component: Link,
                    to: module.path,
                  }
                : {})}
              id={module.id}
              data-testid={module.id}
              sx={{
                width: "100%",
                maxWidth: "280px",
                height: "120px",
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => theme.palette.background.paper,
                "&.Mui-disabled": {
                  color: (theme) => theme.palette.grey[500],
                  backgroundColor: (theme) => theme.palette.background.paper,
                  boxShadow: (theme) => theme.shadows[1],
                },
                fontSize: "48px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": { backgroundColor: "unset" },
              }}
              variant="contained"
              disabled={!module.active}
            >
              {module.id.valueOf() === ModuleID.Ozone.valueOf() ? (
                module.img && module.img()
              ) : (
                <>
                  {module.icon && module.icon({ fontSize: "inherit" })}
                  <Box mt={2}>
                    {module.name && (
                      <Typography sx={{ textAlign: "center" }}>
                        {module.name}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Button>
          );

          return module.active ? (
            button
          ) : (
            <Box
              sx={{
                width: "inherit",
                maxWidth: 280,
                display: "inline",
              }}
            >
              {button}
            </Box>
          );
        })()}
      </Grid>
    ))}
  </Grid>
);

const HomePage = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    document.title = formatMessage({
      defaultMessage: "Modules - Monitoringbook Pro",
      description: "Főoldal modulok cím",
    });
  }, [formatMessage]);

  const {
    isLoading,
    error: queryError,
    activeModules,
    inactiveModules,
  } = useModules();

  const [error, setError] = React.useState<typeof queryError>(null);
  const clearError = () => setError(null);
  utils.useOnChange(queryError, setError);

  return (
    <Page>
      <Container maxWidth="lg" sx={{ pt: 4 }}>
        <ModuleCards
          modules={
            isLoading ? inactiveModules : [...activeModules, ...inactiveModules]
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={!!error}
          autoHideDuration={6000}
          onClose={clearError}
          message={error && getErrorMessage(error)}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={clearError}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Container>
    </Page>
  );
};

export default HomePage;
