import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarProps,
} from "@mui/material";
import { SnackbarState } from "../types";

interface SnackbarStateProps {
  state: SnackbarState | undefined;
  onClose: () => void;
  successColor?: string;
}

const Snackbar = ({
  state,
  onClose,
  successColor,
  ...rest
}: SnackbarProps & SnackbarStateProps) => (
  <MuiSnackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    open={!!state?.message}
    autoHideDuration={
      state?.autoHideDuration === null ? null : state?.autoHideDuration ?? 3000
    }
    onClose={onClose}
    message={state?.message}
    sx={{
      "& > div": {
        backgroundColor: state?.error
          ? "error.main"
          : successColor ?? "primary.main",
      },
    }}
    action={
      state?.autoHideDuration === null && (
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )
    }
    {...rest}
  />
);

export default Snackbar;
