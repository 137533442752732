import ErrorIcon from "@mui/icons-material/Error";
import { Badge } from "@mui/material";
import React, { PropsWithChildren } from "react";
import useAlarmAlerts from "./useAlarmAlerts";

const badgeAnchor = {
  vertical: "bottom",
  horizontal: "right",
} as const;

const errorIconContent = <ErrorIcon color="warning" style={{ fontSize: 22 }} />;

const AlertBadge = ({
  children,
  forwardedRef,
  ...props
}: PropsWithChildren<{
  forwardedRef: React.Ref<HTMLSpanElement>;
}>) => {
  const { count, isError } = useAlarmAlerts();

  const [badgeContent, color] = isError
    ? [errorIconContent, undefined]
    : ([count, "error"] as const);

  return (
    <Badge
      ref={forwardedRef}
      color={color}
      max={999}
      badgeContent={badgeContent}
      anchorOrigin={badgeAnchor}
      sx={{
        alignSelf: "center",
        "& .MuiBadge-badge": {
          right: 5,
          top: 22,
          padding: "0 4px",
        },
      }}
      {...props}
    >
      {children}
    </Badge>
  );
};

export default React.forwardRef<HTMLSpanElement, { children: React.ReactNode }>(
  (props, ref) => <AlertBadge {...props} forwardedRef={ref} />,
);
