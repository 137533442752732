import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Link, Tooltip, styled } from "@mui/material";
import { useIntl } from "react-intl";

const CoordsLabelWrapper = styled("span")({
  display: "flex",
  alignItems: "center",
});

const CoordsLabel = ({
  lat,
  lon,
  inaccurate,
}: {
  lat: number;
  lon: number;
  inaccurate?: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <CoordsLabelWrapper
      sx={{ color: (theme) => (inaccurate ? theme.palette.grey[500] : null) }}
    >
      {lon.toFixed(6)}
      {", "}
      {lat.toFixed(6)}
      <Tooltip title={formatMessage({ defaultMessage: "Open in Google Maps" })}>
        <IconButton
          component={Link}
          href={`https://maps.google.com/?q=${lat},${lon}`}
          target="_blank"
          size="small"
          color="primary"
        >
          <OpenInNewIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </CoordsLabelWrapper>
  );
};

export default CoordsLabel;
