import {
  MbProFilterButton,
  MbProFilterTextField,
} from "@mb-pro-ui/components/table/components";
import { FilterViewProps } from "@mb-pro-ui/components/table/filters/base/types";
import useDebounce from "@mb-pro-ui/components/utils/useDebounce";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Menu, MenuItem, lighten, styled } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment, MomentInput } from "moment";
import { memo, useCallback, useEffect, useState } from "react";
import { IntlFormatters, useIntl } from "react-intl";
import useIntervals from "./useIntervals";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: lighten(theme.palette.primary.light, 0.7),
    color: theme.palette.primary.main,
  },
  "&.Mui-selected:hover": {
    backgroundColor: lighten(theme.palette.primary.light, 0.7),
    color: theme.palette.primary.main,
  },
}));

function mapDateRangeValue(
  value?: {
    from?: string | Moment | null;
    to?: string | Moment | null;
  } | null,
): { from?: string; to?: string } | null {
  const from = value?.from ? moment.utc(value.from) : undefined;
  const to = value?.to ? moment.utc(value.to) : undefined;

  return from?.isValid() || to?.isValid()
    ? { from: from?.format(), to: to?.format() }
    : null;
}

export function serializeDateRange(
  from?: MomentInput | null,
  to?: MomentInput | null,
): string {
  return from || to
    ? `${from ? moment.utc(from).format() : ""} ${
        to ? moment.utc(to).format() : ""
      }`.replace(/:/g, "_")
    : "";
}

export function parseDateRange(param: string | null) {
  if (param) {
    const [from, to] = param
      .replace(/_/g, ":")
      .replace(/,/, " ")
      .split(" ") as [string | undefined, string | undefined];
    return { from, to };
  }
  return null;
}

export const inputFormat = (formatMessage: IntlFormatters["formatMessage"]) =>
  formatMessage({
    defaultMessage: "MM/DD/YYYY HH:mm",
    description: "Intervention table date range filter date input format",
  });

const DateRangeFilter = memo(
  ({ filterId, urlSearchParam, setFilterState }: FilterViewProps) => {
    const [values, setValues] = useState(() =>
      mapDateRangeValue(parseDateRange(urlSearchParam)),
    );
    const debouncedValues = useDebounce(values, 50);

    const [errors, setErrors] = useState(() => ({ from: false, to: false }));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [intervalIdx, setIntervalIdx] = useState<number | null>(null);
    const intervals = useIntervals();

    const { formatMessage } = useIntl();

    const setValue = useCallback(
      (input: { from?: string | Moment; to?: string | Moment } | null) => {
        const value = mapDateRangeValue(input);
        setFilterState(
          filterId,
          { value },
          value && serializeDateRange(value.from, value.to),
        );
      },
      [filterId, setFilterState],
    );

    const selectInterval = useCallback(
      (index: number | null) => {
        setErrors({ from: false, to: false });
        setIntervalIdx(index);
        if (index !== null) {
          const { interval } = intervals[index];
          setValues(mapDateRangeValue({ from: interval[0], to: interval[1] }));
        } else {
          setValues(null);
        }
        setAnchorEl(null);
      },
      [intervals],
    );

    const handleChange = (name: "from" | "to", date: any) => {
      setIntervalIdx(null);
      setErrors((errors) => ({
        ...errors,
        [name]: !!date && !(date as Moment).isValid(),
      }));
      setValues(
        mapDateRangeValue({
          ...(values ?? {}),
          [name]:
            moment.utc(date).format() === "Invalid date"
              ? null
              : moment.utc(date).format(),
        }),
      );
    };

    useEffect(() => {
      if (
        (!debouncedValues?.from || moment(debouncedValues.from).isValid()) &&
        (!debouncedValues?.to || moment(debouncedValues.to).isValid())
      ) {
        setValue(debouncedValues);
      }
    }, [debouncedValues, setValue]);

    // for clearing the filter
    useEffect(() => {
      const dateRangeValues = mapDateRangeValue(parseDateRange(urlSearchParam));

      setValues(dateRangeValues);

      // for clearing the filter button selection
      if (!dateRangeValues) {
        setIntervalIdx(null);
      }
    }, [urlSearchParam]);

    return (
      <Box sx={{ display: "inherit" }}>
        <DesktopDateTimePicker
          value={values?.from ? moment(values.from) : null}
          onChange={(date) => handleChange("from", date)}
          slots={{
            textField: MbProFilterTextField,
          }}
          slotProps={{
            textField: {
              size: "small",
              variant: "outlined",
              error: errors.from,
            },
            field: { clearable: true },
          }}
          disableFuture
          label={formatMessage({
            defaultMessage: "From",
            description: "Interventions table start time filter label",
          })}
        />
        <MbProFilterButton
          endIcon={<MoreHorizIcon />}
          sx={{
            minWidth: "24px",
            paddingRight: "5px",
            paddingLeft: "5px",
            display: "flex",
            justifyContent: "center",
            margin: 0,
          }}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
          active={!!anchorEl}
          emphasize={intervalIdx !== null}
        />
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <StyledMenuItem
            onClick={() => selectInterval(null)}
            selected={intervalIdx === null}
          >
            {formatMessage({
              defaultMessage: "Unset",
              description: "Date range filter unset message",
            })}
          </StyledMenuItem>
          {intervals.map((interval, index) => (
            <StyledMenuItem
              key={interval.label}
              onClick={() => selectInterval(index)}
              selected={index === intervalIdx}
            >
              {interval.label}
            </StyledMenuItem>
          ))}
        </Menu>
        <DesktopDateTimePicker
          value={values?.to ? moment(values.to) : null}
          onChange={(date) => handleChange("to", date)}
          minDate={values?.from ? moment(values.from) : undefined}
          slots={{
            textField: MbProFilterTextField,
          }}
          slotProps={{
            textField: { size: "small", variant: "outlined", error: errors.to },
            field: { clearable: true },
          }}
          disableFuture
          label={formatMessage({
            defaultMessage: "To",
            description: "Interventions table end time filter label",
          })}
        />
      </Box>
    );
  },
);

export default DateRangeFilter;
