function delay(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration * 1000));
}

function backoffDuration(iteration: number) {
  return Math.min(Math.pow(2, iteration), 5) + Math.random();
}

// This class is used to download array buffers from the network. It uses a
// simple exponential backoff algorithm to retry failed downloads.
// Requests are cached, so if multiple requests are made for the same URL, only
// one request will be made to the network.
export default class ArrayBufferDownloadManager {
  #cache = new Map<string, Promise<ArrayBuffer | null>>();

  async download(
    url: string,
    isAborted?: () => boolean,
    abortDuplicate?: boolean,
  ) {
    for (let i = 0; ; i++) {
      const cached = this.#cache.get(url);
      if (cached && abortDuplicate) {
        return null;
      }

      const promise =
        cached ??
        (async () => {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("failed to download array buffer");
          }
          return response.arrayBuffer();
        })();

      promise.finally(() => {
        if (this.#cache.get(url) === promise) {
          this.#cache.delete(url);
        }
      });

      if (!cached) {
        this.#cache.set(url, promise);
      }

      try {
        return await promise.then((arrayBuffer) =>
          cached && arrayBuffer ? arrayBuffer.slice(0) : arrayBuffer,
        );
      } catch (e) {
        console.error(e);
        if (isAborted?.()) {
          return null;
        }

        await delay(backoffDuration(i));
      }
    }
  }
}
