import { Button, TextField } from "@mb-pro-ui/components";
import { isHttpError, useAuth, useUpdate } from "@mb-pro-ui/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../icons/logo.svg";
import { useLocale } from "../locales/LocaleProvider";

const StyledForm = styled("form")(({ theme }) => ({
  width: "70%",
  marginTop: "2rem",
  [theme.breakpoints.down("sm")]: { width: "100%" },
}));

type LoginLocationState = { from?: { pathname: string } } | undefined;

function getFrom(state: LoginLocationState, search: string) {
  const searchParams = new URLSearchParams(search);
  if (searchParams.has("from")) {
    return JSON.parse(searchParams.get("from") ?? '"/home"');
  }
  return state?.from ?? { pathname: "/home" };
}

const useUserLanguageSetter = (cb: () => void) => {
  const { locale } = useLocale();
  const localeRef = useRef(locale);
  useEffect(() => {
    localeRef.current = locale;
  }, [locale]);

  const { mutate, authenticated } = useUpdate("admin/users");
  const mutateRef = useRef(mutate);
  useEffect(() => {
    mutateRef.current = mutate;
  }, [mutate]);

  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  }, [cb]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    mutateRef.current(
      { id: "me", "ui-language": localeRef.current },
      { onSettled: () => cbRef.current() },
    );
  }, [authenticated]);
};

const LoginPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation<LoginLocationState>();
  const { locale, locales, setLocale } = useLocale();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = formatMessage({
      defaultMessage: "Login - Monitoringbook Pro",
    });

    if (sessionStorage.getItem("pin")) {
      sessionStorage.removeItem("pin");
    }
  }, [formatMessage]);

  const {
    signin: { isLoading, isSuccess, isError, error, mutate },
  } = useAuth();

  useUserLanguageSetter(() => {
    const from = getFrom(location.state, location.search);
    const pathname = typeof from === "string" ? from : from.pathname;
    if (pathname.match(/^\/home/)) {
      history.replace(from);
    } else {
      const url = new URL(pathname, window.location.origin);
      if (typeof from !== "string") {
        if (from.search !== undefined) {
          url.search = from.search;
        }
        if (from.hash !== undefined) {
          url.hash = from.hash;
        }
      }
      window.location.replace(url.toString());
    }
  });

  const handleLanguageSelect = (lang: string) => {
    setLocale(lang);
    localStorage.setItem("lang", lang);
  };

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      mutate({ username, password });
    },
    [mutate, username, password],
  );

  const errorMessage = useMemo(() => {
    if (error && isHttpError(error) && error.status === 401) {
      return formatMessage({
        defaultMessage: "Invalid username or password",
        description: "LoginPage invalid username or password",
      });
    } else if (error) {
      return formatMessage(
        {
          defaultMessage: "Could not log in: {message}",
          description: "LoginPage could not login error message",
        },
        { message: error.message },
      );
    } else {
      return "\u00a0";
    }
  }, [error, formatMessage]);

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: "100vh",
      }}
    >
      <Container
        maxWidth="md"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          padding: "5%",
          position: "relative",
          [theme.breakpoints.up("md")]: { borderRadius: "20px" },
        })}
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <Box
            sx={(theme) => ({
              alignSelf: "flex-end",
              color: theme.palette.text.secondary,
              position: "absolute",
              top: "30px",
              right: "30px",
              [theme.breakpoints.down("sm")]: { top: "10px", right: "10px" },
            })}
          >
            {locales.map((lang) => (
              <ButtonBase
                sx={{
                  "&:not(:first-of-type):before": {
                    content: '"/"',
                    paddingLeft: "0.2rem",
                    paddingRight: "0.2rem",
                    textDecoration: "initial",
                  },
                }}
                key={lang}
                onClick={() => handleLanguageSelect(lang)}
                disabled={locale === lang}
              >
                <Typography sx={[locale === lang && { fontWeight: "bold" }]}>
                  {lang}
                </Typography>
              </ButtonBase>
            ))}
          </Box>
          <img src={logo} alt="" width="64px" height="73px"></img>
          <Typography
            sx={(theme) => ({
              textTransform: "uppercase",
              fontWeight: "bold",
              "& > span": { color: theme.palette.primary.main },
              [theme.breakpoints.down("sm")]: {
                fontSize: "1.5rem",
                marginBottom: "20px",
              },
              "@media (max-width:350px)": { display: "none" },
            })}
            variant="h1"
          >
            <span>Monitoringbook</span> Pro
          </Typography>
          <Hidden smDown>
            <Typography sx={{ marginBottom: "4rem" }} variant="subtitle1">
              <FormattedMessage
                defaultMessage="Please login below to proceed"
                description="LoginPage subtitle"
              />
            </Typography>
          </Hidden>
          <StyledForm autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              alignContent="center"
              alignItems="center"
            >
              <TextField
                sx={{ margin: "0.2rem", width: "100%" }}
                type="text"
                placeholder={formatMessage({
                  defaultMessage: "Username",
                  description: "LoginPage username",
                })}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
                required
                error={isError}
                autoComplete="username"
              ></TextField>
              <TextField
                sx={{ margin: "0.2rem", width: "100%" }}
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder={formatMessage({
                  defaultMessage: "Password",
                  description: "LoginPage password",
                })}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((flag) => !flag)}
                        data-testid="toggle-visibility"
                        size="large"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon color="primary" />
                        ) : (
                          <VisibilityIcon color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={isError}
                helperText={isError ? errorMessage : "\u00a0"}
                autoComplete="current-password"
              />
              {isLoading || isSuccess ? (
                <CircularProgress
                  data-testid="loading-spinner"
                  sx={{ marginTop: "4rem" }}
                  size={42} // height of Button size="large"
                />
              ) : (
                <Button
                  sx={{
                    marginTop: "4rem",
                    paddingRight: "4.5rem",
                    paddingLeft: "4.5rem",
                    fontWeight: (theme) => theme.typography.fontWeightLight,
                  }}
                  size="large"
                  type="submit"
                >
                  <FormattedMessage
                    defaultMessage="Login"
                    description="LoginPage login button"
                  />
                </Button>
              )}
            </Grid>
          </StyledForm>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LoginPage;
