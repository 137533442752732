import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { styled } from "@mui/material";

const AlertIcon = styled(NotificationImportantIcon, {
  shouldForwardProp(propName) {
    return propName !== "blink";
  },
})<{ blink?: boolean }>(({ theme, blink = true }) =>
  blink
    ? {
        color: "transparent",
        "@keyframes blinkIcon": {
          "0%, 100%": {
            color: "transparent",
          },
          "50%": {
            color: theme.palette.error.main,
          },
        },
        animation: "blinkIcon 1s ease-in-out infinite",
      }
    : { color: theme.palette.error.main },
);

export default AlertIcon;
