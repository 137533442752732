import { useCreate } from "@mb-pro-ui/utils";
import { ID, Type } from "@mb-pro-ui/utils/types";
import { useIntl } from "react-intl";
import { ModalFormProps } from "../types";

const useUseCreate = <T extends { type: string; id: ID }>(
  fulltype: Type<T["type"]>,
  {
    refetch,
    setSnackbarState,
    onFormClose,
    onSuccess,
    onError,
    onMutate,
  }: Pick<
    ModalFormProps,
    | "refetch"
    | "setSnackbarState"
    | "onFormClose"
    | "onError"
    | "onSuccess"
    | "onMutate"
  >,
) => {
  const { formatMessage } = useIntl();

  return useCreate(fulltype, {
    onMutate: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Save in progress" }),
      });
      onMutate?.();
    },
    onSuccess: () => {
      refetch?.();
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Saved succesfully" }),
      });
      onFormClose();
      onSuccess?.();
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({
          defaultMessage: "Unsuccessfull operation",
        }),
        error: true,
      });
      onError?.();
    },
  });
};

export default useUseCreate;
