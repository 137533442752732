import { EnhancedTable, EnhancedTableProps } from "@mb-pro-ui/components/table";
import { useEvents, useGetAll, useStorageState } from "@mb-pro-ui/utils";
import { RelatedType, WithFields, WithIncludes } from "@mb-pro-ui/utils/types";
import {
  AlertedCustomer as _AlertedCustomer,
  Cdec as _Cdec,
} from "@mb-pro-ui/utils/types/alarm";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import InfoIcon from "@mui/icons-material/Info";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import {
  Badge,
  Box,
  SvgIcon,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";
import useMe from "../../../hooks/useMe";
import { ReactComponent as InterventionsMenuIcon } from "../../../icons/InterventionsMenu.svg";
import useTimestampFormat from "../../../locales/useTimestampFormat";
import { CounterIcon } from "../../audio";
import { useAudio } from "../../audio/AudioPlayerProvider";
import { useErrorAlertManager } from "../../audio/ErrorAlertManager";
import AlertIcon from "../../utils/AlertIcon";
import BootstrapTooltip from "../../utils/BootstrapTooltip";
import LinkButton from "../../utils/LinkButton";
import LinkStyleText from "../../utils/LinkStyleText";
import CdecModal from "../cdec/CdecModal";
import CustomerModal from "../cdec/CustomerModal";
import SortButton, { SortBy } from "../cdec/header/SortButton";
import Timer24h from "../interventions/Timer24h";
import InterventionButton from "./header/InterventionButton";

const alertedCustomerFields = {
  "alerted-customers": [
    "customer",
    "intervention",
    "needs-intervention",
    "needs-intervention-count",
    "cdec",
    "first-cdec",
    "last-cdec",
  ],
  customers: [
    "name",
    "account",
    "address",
    "email",
    "active",
    "phone",
    "mobile",
  ],
  interventions: [
    "start-time",
    "close-time",
    "manual-log-entries",
    "fresh-cdec-count",
    "customer",
    "start-event",
    "unitid",
    "session",
    "logs",
  ],
  log: ["session"],
  sessions: ["operator"],
  operators: ["login", "employee"],
  employees: ["name"],
  cdec: [
    "localized-description",
    "color",
    "arrived",
    "sent",
    "event-code",
    "account",
    "unitid",
    "partition-number",
    "zone-number",
    "active",
    "has-valid-coords",
    "latitude",
    "longitude",
    "customer",
    "event",
    "category-priority",
  ],
} as const;

const alertedCustomerIncludes = {
  customer: {},
  intervention: {
    customer: {},
    session: {
      operator: { employee: {} },
    },
    "start-event": {
      customer: {},
    },
    logs: {
      session: {
        operator: { employee: {} },
      },
    },
  },
  cdec: { customer: {} },
  "first-cdec": { customer: {} },
  "last-cdec": { customer: {} },
} as const;

type __AlertedCustomer = WithIncludes<
  WithFields<_AlertedCustomer, typeof alertedCustomerFields>,
  typeof alertedCustomerIncludes
>;

type AlertedCustomer = Omit<
  __AlertedCustomer,
  "cdec" | "first-cdec" | "last-cdec"
> & {
  cdec: RelatedType<__AlertedCustomer["cdec"]> | null;
  "first-cdec": RelatedType<__AlertedCustomer["first-cdec"]> | null;
  "last-cdec": RelatedType<__AlertedCustomer["last-cdec"]> | null;
};

type ACCdec = RelatedType<AlertedCustomer["cdec"]>;

function sortCdecForIntervention(a: ACCdec | null, b: ACCdec | null) {
  const p = (b?.["category-priority"] ?? 0) - (a?.["category-priority"] ?? 0);
  if (p === 0) {
    return (
      new Date(b?.arrived ?? 0).getTime() - new Date(a?.arrived ?? 0).getTime()
    );
  }
  return p;
}

function sortAlertedCustomer(a: AlertedCustomer, b: AlertedCustomer) {
  if (a["needs-intervention"]) {
    if (b["needs-intervention"]) {
      return sortCdecForIntervention(a.cdec, b.cdec);
    } else {
      return -1;
    }
  } else if (b["needs-intervention"]) {
    return 1;
  } else {
    return sortCdecForIntervention(a.cdec, b.cdec);
  }
}

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 12,
    padding: 0,
  },
}));

export const onlyInFirst = <T extends { id: string }>(
  first: T[],
  second: T[],
): T[] => {
  let inFirst: T[] = [];
  let newItem: T | null = null;

  if (first && second) {
    for (let firstItem of first) {
      newItem = firstItem;
      for (let secondItem of second) {
        if (newItem.id === secondItem.id) {
          newItem = null;
          break;
        }
      }
      if (newItem) {
        inFirst.push(newItem);
      }
    }
  }

  return inFirst;
};

const errorAlertID = "interventionCdecWindowErrorAlertID";

type TTCdec = ACCdec;

/*WithIncludes<
  WithFields<
    _Cdec,
    {
      cdec: [
        "localized-description",
        "category-alert",
        "color",
        "category-priority",
        "arrived"
      ];
    }
  >,
  {}
>;
*/

const AlertEventsInteractiveTooltip = styled(
  ({
    className,
    alertedCustomer,
    ...props
  }: Omit<TooltipProps, "title"> & {
    alertedCustomer: AlertedCustomer;
  }) => {
    const { formatMessage } = useIntl();
    const { formatTimestamp } = useTimestampFormat();

    const tooltipColumns = useMemo(
      (): Column<TTCdec>[] => [
        {
          id: "localized-description",
          accessor: "localized-description",
          width: 100,
          Header: formatMessage({ defaultMessage: "Alert event" }),
          Cell: ({ value, row }) => (
            <LinkStyleText
              color={row.original.color}
              onClick={() => row.original && handleCdecModalOpen(row.original)}
            >
              {value}
            </LinkStyleText>
          ),
        },
        {
          id: "arrived",
          accessor: "arrived",
          Header: formatMessage({
            defaultMessage: "Arrival time",
            description: "Események táblázat fejléc érekezési idő",
          }),
          width: 75,
          Cell: ({ value }) => <span>{formatTimestamp(value)}</span>,
        },
        {
          id: "category-priority",
          accessor: (cdec) => cdec["category-priority"] ?? 0,
          maxWidth: 30,
          Header: () => (
            <Tooltip title={formatMessage({ defaultMessage: "Priority" })}>
              <DoubleArrowIcon
                fontSize="small"
                color="primary"
                sx={{ transform: "rotate(-90deg)" }}
              />
            </Tooltip>
          ),
        },
      ],
      [formatMessage, formatTimestamp],
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const limit = 100;
    const {
      data: customerCdecs,
      refetch,
      status: queryStatus,
    } = useGetAll<TTCdec>("alarm/cdec", {
      enabled: isOpen && !!alertedCustomer["first-cdec"],
      page: { limit },
      refetchInterval: 20000,
      refetchIntervalInBackground: false,
      keepPreviousData: true,
      filter: {
        arrived: {
          gte: alertedCustomer["first-cdec"]?.arrived,
        },
        customer: { eq: alertedCustomer.customer.id },
      },
      include: alertedCustomerIncludes.cdec,
      fields: {
        cdec: alertedCustomerFields.cdec,
        customers: alertedCustomerFields.customers,
      },
      sort: ["-arrived", "-id"],
    });

    useEvents(["cdec-insert"], () => {
      refetch();
    });

    const [cdecModalOpen, setCdecModalOpen] = useState<boolean>(false);
    const [selectedCdec, setSelectedCdec] = useState<ACCdec | null>(null);

    const handleCdecModalOpen = (cdec: ACCdec) => {
      setSelectedCdec(cdec);
      setCdecModalOpen(true);
    };

    const handleCdecModalClose = () => {
      setCdecModalOpen(false);
      setSelectedCdec(null);
    };

    return (
      <>
        <Tooltip
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          placement="right"
          {...props}
          title={
            <TooltipTable
              data={customerCdecs}
              columns={tooltipColumns}
              prefix={
                <Typography color="primary.contrastText">
                  {customerCdecs && customerCdecs.length === limit ? (
                    <FormattedMessage
                      defaultMessage="{account} customer's last {limit, number} events"
                      values={{
                        limit,
                        account: alertedCustomer.customer.account,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="{account} customer's events"
                      values={{ account: alertedCustomer.customer.account }}
                    />
                  )}
                </Typography>
              }
              queryStatus={queryStatus}
            />
          }
          classes={{ popper: className }}
        />
        {selectedCdec && (
          <CdecModal
            cdec={selectedCdec}
            open={cdecModalOpen}
            onClose={handleCdecModalClose}
          />
        )}
      </>
    );
  },
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    width: "500px",
    backgroundColor: "transparent",
    padding: 0,
  },
}));

type TooltipTableProps = {
  data: TTCdec[] | undefined;
  columns: Column<TTCdec>[];
  prefix: ReactElement;
  queryStatus: EnhancedTableProps<any, any>["queryStatus"];
};

const TooltipTable = ({
  data,
  columns,
  prefix,
  queryStatus,
}: TooltipTableProps) => (
  <EnhancedTable
    queryStatus={queryStatus}
    data={data ?? []}
    columns={columns}
    disableGlobalFilter
    prefix={prefix}
    showResultCount={false}
    headerSx={{
      height: "40px",
      minHeight: "40px",
    }}
    innerSx={{
      height: "200px",
      maxHeight: "200px",
    }}
    sx={{
      boxShadow: `0px 0px 10px 1px`,
      display: "block",
    }}
  />
);

type ICdec = WithIncludes<
  WithFields<
    _Cdec,
    {
      cdec: [
        "category-priority",
        "category-sound",
        "category-alert",
        "active",
        "customer",
      ];
      customers: ["name", "account"];
    }
  >,
  { customer: {} }
>;

function useAlertsWitoutIntervention() {
  return useGetAll<ICdec>("alarm/cdec", {
    page: { limit: 1000 },
    refetchInterval: 20000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    filter: {
      "intervention-needed": { is: "true" },
      intervention: { is: "null" },
      "category-alert": { is: "true" },
      customer: { null: "false" },
      active: { is: "true" },
    },
    include: { customer: {} },
    fields: {
      cdec: [
        "category-priority",
        "category-sound",
        "category-alert",
        "active",
        "customer",
      ],
      intervention: ["+log-entries"],
    },
    sort: ["-category-priority", "id"],
  });
}

function useCustomersWithAlerts() {
  return useGetAll<AlertedCustomer>("alarm/alerted-customers", {
    include: alertedCustomerIncludes,
    fields: alertedCustomerFields,
    refetchInterval: 20000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
  });
}

export const InterventionCdecWindow = () => {
  const { setErrorState } = useErrorAlertManager();
  const { allLoopRefs, isLooping } = useAudio();
  const [cdecModalOpen, setCdecModalOpen] = useState<boolean>(false);
  const [customerModalOpen, setCustomerModalOpen] = useState<boolean>(false);
  const [selectedCdec, setSelectedCdec] = useState<ACCdec | null>(null);
  const [sortBy, setSortBy] = useStorageState<SortBy>("sortBy", "default");

  const [enhancedTableWidth, setEnhancedTableWidth] = useState<
    undefined | number
  >();

  const isFirstDataRef = useRef(true);

  const { formatMessage } = useIntl();
  const { data: me, isLoading: isMeLoading } = useMe();

  const interventionAllowed =
    !me?.["alarm-operator"]?.["read-only"] && !isMeLoading;

  const {
    data: iCdecs,
    status: queryStatus,
    isSuccess,
    isFetchedAfterMount,
    refetch,
  } = useAlertsWitoutIntervention();

  const {
    data,
    refetch: r,
    status: alertedCustomersQueryStatus,
    failureCount,
  } = useCustomersWithAlerts();

  const sortedData = useMemo(
    () =>
      data
        ?.slice()
        .sort((a, b) => {
          if (sortBy === "default") {
            return sortAlertedCustomer(a, b);
          } else if (sortBy === "account") {
            return a.customer.account.localeCompare(b.customer.account);
          } else if (sortBy === "ts-asc") {
            return (
              new Date(a?.["first-cdec"]?.arrived ?? 0).getTime() -
              new Date(b?.["first-cdec"]?.arrived ?? 0).getTime()
            );
          } else if (sortBy === "ts-desc") {
            return (
              new Date(b?.["last-cdec"]?.arrived ?? 0).getTime() -
              new Date(a?.["last-cdec"]?.arrived ?? 0).getTime()
            );
          } else {
            return sortAlertedCustomer(a, b);
          }
        })
        .filter(
          (item) =>
            typeof item.intervention !== "string" &&
            typeof item.cdec !== "string",
        ) ?? [],
    [data, sortBy],
  );

  useEvents(["cdec-insert", "interventions-insert"], () => {
    refetch();
    r();
  });

  useEffect(() => {
    if (isSuccess && isFetchedAfterMount) {
      isFirstDataRef.current = false;
    }
  }, [isSuccess, isFetchedAfterMount]);

  useEffect(() => {
    if (interventionAllowed) {
      setErrorState(errorAlertID, queryStatus === "error");
    }
  }, [queryStatus, setErrorState, interventionAllowed]);

  const handleCdecModalOpen = (cdec: ACCdec) => {
    setSelectedCdec(cdec);
    setCdecModalOpen(true);
  };

  const handleCustomerModalOpen = (cdec: ACCdec) => {
    setSelectedCdec(cdec);
    setCustomerModalOpen(true);
  };

  const handleCustomerModalClose = () => {
    setCustomerModalOpen(false);
    setSelectedCdec(null);
  };

  const handleCdecModalClose = () => {
    setCdecModalOpen(false);
    setSelectedCdec(null);
  };

  const { formatTimestamp } = useTimestampFormat();

  const columns = useMemo(
    (): Column<AlertedCustomer>[] => [
      {
        id: "fix",
        accessor: (alertedCustomer) => alertedCustomer.cdec?.id ?? null,
        Cell: ({ value, row }: CellProps<AlertedCustomer>) => {
          const {
            intervention,
            "needs-intervention": customerNeedIv,
            "needs-intervention-count": customerNeedIvCnt,
          } = row.original;

          return (
            <StyledBadge
              badgeContent={
                (customerNeedIv
                  ? customerNeedIvCnt
                  : Number(intervention?.["fresh-cdec-count"])) || null
              }
              color={customerNeedIv ? "error" : "primary"}
              max={999}
            >
              <LinkButton
                disabled={!customerNeedIv && !intervention}
                url={
                  customerNeedIv
                    ? `/alarm/interventions/create?start-events=${value}`
                    : `/alarm/interventions/${intervention?.id}`
                }
                color={customerNeedIv ? "warning" : "primary"}
                sx={{ backgroundColor: "common.white" }}
                icon={
                  <SvgIcon component={InterventionsMenuIcon} inheritViewBox />
                }
                message={<FormattedMessage defaultMessage="Intervention" />}
              />
            </StyledBadge>
          );
        },
      },
      {
        id: "customer",
        accessor: (alertedCustomer) =>
          alertedCustomer.customer.name
            ? `${alertedCustomer.customer.account} - ${alertedCustomer.customer.name}`
            : alertedCustomer.customer.account,
        width: "100",
        Header: formatMessage({ defaultMessage: "Customer" }),
        Cell: ({ value, row }: CellProps<AlertedCustomer>) => (
          <LinkStyleText
            onClick={() =>
              row.original.cdec && handleCustomerModalOpen(row.original.cdec)
            }
          >
            {value}
          </LinkStyleText>
        ),
      },
      {
        id: "description",
        accessor: (alertedCustomer) =>
          alertedCustomer[
            sortBy === "ts-asc"
              ? "first-cdec"
              : sortBy === "ts-desc"
                ? "last-cdec"
                : "cdec"
          ]?.["localized-description"] ?? "",
        width: "100",
        Header: formatMessage({ defaultMessage: "Event description" }),
        Cell: ({ value, row }: CellProps<AlertedCustomer>) => {
          const cdec =
            row.original[
              sortBy === "ts-asc"
                ? "first-cdec"
                : sortBy === "ts-desc"
                  ? "last-cdec"
                  : "cdec"
            ];

          // const customerCdecs = alertCdecsByCustomer?.get(customerId);

          return (
            <Box>
              <AlertEventsInteractiveTooltip alertedCustomer={row.original}>
                <LinkStyleText
                  color={cdec?.color}
                  onClick={() => cdec && handleCdecModalOpen(cdec)}
                >
                  {value}
                </LinkStyleText>
              </AlertEventsInteractiveTooltip>
            </Box>
          );
        },
      },
      {
        id: "operator",
        accessor: (alertedCustomer) =>
          alertedCustomer.intervention?.session?.operator?.login,
        width: 60,
        Header: formatMessage({
          defaultMessage: "Operator",
          description: "Beavatkozások táblázat fejléc operátor",
        }),
        Cell: ({
          value,
          row: {
            original: { intervention },
          },
        }: CellProps<AlertedCustomer, string | undefined>) =>
          value ? (
            <Tooltip title={intervention?.session?.operator?.login}>
              <span>
                {intervention?.session?.operator?.employee?.name ||
                  intervention?.session?.operator?.login}
              </span>
            </Tooltip>
          ) : (
            <Typography
              component="span"
              variant="caption"
              sx={{
                backgroundColor: "text.primary",
                color: "common.white",
                borderRadius: "4px",
                paddingLeft: "3px",
                lineHeight: "1.3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {formatMessage({ defaultMessage: "none" })}
              <PriorityHighOutlinedIcon fontSize="inherit" color="warning" />
            </Typography>
          ),
      },
      {
        id: "start-time",
        accessor: (alertedCustomer) =>
          alertedCustomer.intervention?.["start-time"],
        width: 50,
        Header: formatMessage({
          defaultMessage: "Elapsed time",
          description: "Beavatkozások táblázat fejléc eltelt idő",
        }),
        Cell: ({ value }: CellProps<AlertedCustomer>) => (
          <Tooltip title={formatTimestamp(value)}>
            <Timer24h startTime={value} />
          </Tooltip>
        ),
      },
      {
        id: "alert-icon",
        width: 40,
        Header: () => (
          <Tooltip title={formatMessage({ defaultMessage: "Event icons" })}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        ),
        accessor: (alertedCustomer) => alertedCustomer.customer.id,
        Cell: ({ value, row }: CellProps<AlertedCustomer>) => {
          const iv = row.original.intervention;
          const manualLogs = iv?.["manual-log-entries"];

          return (
            <>
              {allLoopRefs.has(value) ? (
                <BootstrapTooltip
                  title={formatMessage({ defaultMessage: "Alert" })}
                  backgroundColor="error"
                >
                  <AlertIcon fontSize="small" />
                </BootstrapTooltip>
              ) : null}
              {manualLogs && manualLogs.length > 0 && (
                <Tooltip
                  title={
                    <>
                      <div>
                        {formatMessage({
                          defaultMessage: "Contains operator log:",
                        })}
                      </div>
                      <div>{`${[
                        ...(iv?.logs?.reduce(
                          (acc, curr) =>
                            acc.add(
                              (curr?.session?.operator?.employee?.name ||
                                curr?.session?.operator?.login) ??
                                "",
                            ),
                          new Set(),
                        ) ?? []),
                      ].join(", ")}`}</div>
                    </>
                  }
                >
                  <NoteAltIcon color="primary" fontSize="small" />
                </Tooltip>
              )}
            </>
          );
        },
      },
    ],
    [formatMessage, allLoopRefs, sortBy, formatTimestamp],
  );

  const prefix = (
    <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
      <Typography color="primary.contrastText">
        <FormattedMessage defaultMessage="Interventions" />
      </Typography>
    </Box>
  );

  const postfix = (
    <Box maxHeight="48px" display="flex" flexDirection="row">
      <SortButton
        sortBy={sortBy}
        onSortByChange={(value) => setSortBy(value ?? "default")}
      />
      {interventionAllowed && (
        <InterventionButton
          interventionCdecs={iCdecs ?? []}
          interventionNeeded={iCdecs?.length}
          disabled={!iCdecs?.length}
          nextId={iCdecs?.[0]?.id}
        />
      )}
    </Box>
  );

  const hiddenColumns = [
    ...(interventionAllowed ? [] : ["fix", "alert-icon"]),
    ...(enhancedTableWidth && enhancedTableWidth < 650 ? ["start-time"] : []),
    ...(enhancedTableWidth && enhancedTableWidth < 550 ? ["operator"] : []),
    ...(enhancedTableWidth && enhancedTableWidth < 450 ? ["description"] : []),
  ];

  return (
    <>
      <EnhancedTable
        ref={(element) => {
          setEnhancedTableWidth(element?.clientWidth);
        }}
        data={sortedData}
        columns={columns}
        hiddenColumns={hiddenColumns}
        disableGlobalFilter
        queryStatus={alertedCustomersQueryStatus}
        showResultCount={false}
        overscanCount={20}
        emptyIcon={DoneOutlineIcon}
        emptyMessage={formatMessage({
          defaultMessage: "No intervention required",
          description:
            "Operator Page Intervention needed cdecs table placeholder text",
        })}
        errorIcon={CounterIcon}
        errorIconProps={{ failureCount }}
        errorMessage=""
        prefix={prefix}
        postfix={postfix}
        sx={{ height: "100%", flex: 1, overflow: "hidden" }}
        headerSx={{
          backgroundColor: isLooping ? "error.main" : undefined,
          transition: "background-color 0.5s ease-in-out",
        }}
      />
      {selectedCdec && (
        <CustomerModal
          customer={selectedCdec.customer}
          open={customerModalOpen}
          onClose={handleCustomerModalClose}
        />
      )}
      {selectedCdec && (
        <CdecModal
          cdec={selectedCdec}
          open={cdecModalOpen}
          onClose={handleCdecModalClose}
        />
      )}
    </>
  );
};

export default InterventionCdecWindow;
