import { SvgIcon } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as CustomerManagerMenuIcon } from "../../icons/CustomerManagerMenu.svg";
import { ReactComponent as ExportsMenuIcon } from "../../icons/ExportsMenu.svg";
import { ReactComponent as DashboardIcon } from "../../icons/meas/Dashboard.svg";
import { ReactComponent as SystemSettingsMenuIcon } from "../../icons/system_settings/SystemSettings.svg";
import { MeasRoutes } from "../types";

const useMeasMenuLinks = () => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => [
      {
        icon: (
          <SvgIcon component={DashboardIcon} sx={{ strokeLinejoin: "round" }} />
        ),
        href: MeasRoutes.Dashboards,
        label: formatMessage({ defaultMessage: "Dashboards" }),
      },
      {
        icon: (
          <SvgIcon component={CustomerManagerMenuIcon} viewBox="0 0 50 50" />
        ),
        href: MeasRoutes.Customers,
        label: formatMessage({ defaultMessage: "Customers" }),
      },
      {
        icon: <SvgIcon component={ExportsMenuIcon} />,
        href: MeasRoutes.Export,
        label: formatMessage({ defaultMessage: "Export" }),
      },
      {
        icon: <SvgIcon component={SystemSettingsMenuIcon} />,
        href: MeasRoutes.Settings,
        label: formatMessage({
          defaultMessage: "Settings",
          description: "Main menu System Settings label",
        }),
      },
    ],
    [formatMessage],
  );
};

export default useMeasMenuLinks;
