import { default as ArrayBufferDownloadManager } from "./ArrayBufferDownloadManager";

const audioContextOptions: AudioContextOptions = {
  latencyHint: "interactive",
  sampleRate: 44100,
};

interface SourceInfo {
  url: string;
  source: AudioBufferSourceNode;
  refs: Set<string>;
  state:
    | "playing"
    | "downloading"
    | "decoding"
    | "initial"
    | "failed"
    | "suspended";
}

const AUDIO_DEBUG = ((window as any).AUDIO_DEBUG = {
  log: Array.from({ length: 10000 }) as any[][],
  idx: 0,
  push(value: any[]) {
    this.log[this.idx] = value;
    this.idx = (this.idx + 1) % this.log.length;
  },
  print() {
    console.log(this.log.slice(this.idx), this.log.slice(0, this.idx));
  },
});

function logAudioDebug(...args: any[]) {
  AUDIO_DEBUG.push([new Date(), ...args]);
}

function teardownSource(source: AudioBufferSourceNode) {
  try {
    source.stop();
  } catch (e) {
    console.error(e);
  }
  try {
    source.disconnect();
  } catch (e) {
    console.error(e);
  }
}

// This is a very short, silent audio file
const silence =
  "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";

// Returns true if autoplay is allowed, false otherwise
export async function checkAutoplayPolicy() {
  try {
    await Promise.race([
      new Promise((_, reject) => setTimeout(() => reject("timeout"), 1000)),
      new Audio(silence).play(),
    ]);
    return true;
  } catch (e) {
    return false;
  }
}

export class AudioEngine {
  #audioContext: AudioContext = new AudioContext(audioContextOptions);
  #gainNode: GainNode = this.#audioContext.createGain();
  #audioBufferCache: Map<string, AudioBuffer> = new Map();
  #loopingSources: Map<string, SourceInfo> = new Map();
  #autoplayUnlocked?: boolean;
  #tearedDown: boolean = false;
  #downloadManager = new ArrayBufferDownloadManager();
  #allLoopRefs = new Set<string>();
  #refArrayDirty: boolean = false;

  onIsLoopingChange?: (isLooping: boolean) => void;
  onIsUnlockedChange?: (isUnlocked: boolean) => void;
  onAllLoopRefsChange?: (allLoopRefs: Set<string>) => void;

  constructor(volume: number) {
    this.#gainNode.gain.value = volume;
    this.#gainNode.connect(this.#audioContext.destination);

    logAudioDebug("constructor");
  }

  get isLooping() {
    return this.#loopingSources.size > 0;
  }

  get isUnlocked() {
    return this.#autoplayUnlocked;
  }

  set #isUnlocked(value: boolean) {
    if (this.#autoplayUnlocked === value) {
      return;
    }

    this.#autoplayUnlocked = value;
    this.onIsUnlockedChange?.(value);
  }

  tryUnlock() {
    if (this.#autoplayUnlocked) {
      return;
    }

    this.#unlockAutoplay().catch((e) => console.error(e));
  }

  async preloadAudio(url: string) {
    if (this.#audioBufferCache.has(url)) {
      return;
    }

    logAudioDebug("preloadAudio", url);

    const arrayBuffer = await this.#downloadManager.download(
      url,
      () => this.#tearedDown,
      true,
    );
    if (!arrayBuffer) {
      return;
    }

    const buffer = await this.#audioContext.decodeAudioData(arrayBuffer);
    this.#audioBufferCache.set(url, buffer);
  }

  setVolume(volume: number) {
    this.#gainNode.gain.setTargetAtTime(
      volume,
      this.#audioContext.currentTime,
      0.5,
    );
  }

  stopLoops(matchRef?: string, matchUrl?: string) {
    if (matchUrl) {
      this.#stopLoop(matchUrl, matchRef);
    } else {
      for (const url of this.#loopingSources.keys()) {
        this.#stopLoop(url, matchRef);
      }
    }
    logAudioDebug("stopLoops", matchRef, matchUrl);
    this.#updateAllLoopRefs();
  }

  async startLoop(url: string, ref: string) {
    logAudioDebug("startLoop", url, ref);
    await this.#unlockAutoplay();

    const existingSourceInfo = this.#loopingSources.get(url);
    const originalRefCount = existingSourceInfo?.refs.size ?? 0;

    if (existingSourceInfo) {
      if (existingSourceInfo.state !== "failed") {
        existingSourceInfo.refs.add(ref);
        if (existingSourceInfo.refs.size !== originalRefCount) {
          this.#refArrayDirty = true;
          this.#updateAllLoopRefs();
        }
        return;
      } else {
        teardownSource(existingSourceInfo.source);
        this.#deleteLoopingSource(url);
      }
    }

    let buffer = this.#audioBufferCache.get(url);
    const source = new AudioBufferSourceNode(this.#audioContext, {
      loop: true,
      buffer,
    });

    const sourceInfo: SourceInfo = {
      url,
      source,
      refs: new Set([ref, ...(existingSourceInfo?.refs ?? [])]),
      state: "initial",
    };

    this.#setLoopingSource(url, sourceInfo);

    if (sourceInfo.refs.size !== originalRefCount) {
      this.#refArrayDirty = true;
      this.#updateAllLoopRefs();
    }

    const isAborted = () => this.#loopingSources.get(url) !== sourceInfo;

    if (buffer) {
      this.#playSource(sourceInfo);
      return;
    }

    sourceInfo.state = "downloading";

    try {
      const arrayBuffer = await this.#downloadManager.download(url, isAborted);
      if (isAborted()) {
        return;
      } else if (!arrayBuffer) {
        sourceInfo.state = "failed";
        return;
      }

      sourceInfo.state = "decoding";

      buffer = await this.#audioContext.decodeAudioData(arrayBuffer);
      this.#audioBufferCache.set(url, buffer);
      if (isAborted()) {
        return;
      }

      await this.#unlockAutoplay();
      if (isAborted()) {
        return;
      }

      source.buffer = buffer;
      this.#playSource(sourceInfo);
    } catch (e) {
      console.error(e);
      sourceInfo.state = "failed";
    }
  }

  isMuted() {
    return this.#gainNode.gain.value === 0;
  }

  async playOnce(url: string) {
    if (this.isMuted()) {
      return;
    }

    await this.#unlockAutoplay();

    let buffer = this.#audioBufferCache.get(url);
    if (!buffer) {
      const arrayBuffer = await this.#downloadManager.download(
        url,
        () => this.#tearedDown,
      );
      if (!arrayBuffer) {
        return;
      }

      const buffer = await this.#audioContext.decodeAudioData(arrayBuffer);
      this.#audioBufferCache.set(url, buffer);
      await this.#unlockAutoplay();
      if (!this.#autoplayUnlocked) {
        return;
      }
    }

    const source = new AudioBufferSourceNode(this.#audioContext, { buffer });
    source.connect(this.#gainNode);
    source.start();
  }

  async tearDown() {
    this.#tearedDown = true;
    this.stopLoops();
    this.#audioBufferCache.clear();
    this.#loopingSources.clear();
    this.#gainNode.disconnect();
    await this.#audioContext.close();
  }

  #updateAllLoopRefs() {
    if (!this.#refArrayDirty) {
      return;
    }

    this.#allLoopRefs = new Set(
      Array.from(this.#loopingSources.values()).flatMap((sourceInfo) =>
        Array.from(sourceInfo.refs),
      ),
    );
    this.#refArrayDirty = false;
    this.onAllLoopRefsChange?.(this.#allLoopRefs);
  }

  #setLoopingSource(url: string, sourceInfo: SourceInfo) {
    const isLooping = this.#loopingSources.size > 0;
    this.#loopingSources.set(url, sourceInfo);
    if (!isLooping) {
      this.onIsLoopingChange?.(true);
    }
  }

  #deleteLoopingSource(url: string) {
    if (this.#loopingSources.delete(url) && this.#loopingSources.size === 0) {
      this.onIsLoopingChange?.(false);
    }
  }

  #stopLoop(url: string, matchRef?: string) {
    const sourceInfo = this.#loopingSources.get(url);
    if (!sourceInfo) {
      return;
    }

    const originalRefCount = sourceInfo.refs.size;

    if (!matchRef) {
      sourceInfo.refs.clear();
    } else {
      for (const ref of sourceInfo.refs) {
        if (ref === matchRef) {
          sourceInfo.refs.delete(ref);
        }
      }
    }

    if (sourceInfo.refs.size !== originalRefCount) {
      this.#refArrayDirty = true;
    }

    if (sourceInfo.refs.size === 0) {
      logAudioDebug("stopLoop", url);
      teardownSource(sourceInfo.source);
      this.#deleteLoopingSource(url);
    }
  }

  #playSource(sourceInfo: SourceInfo) {
    try {
      if (sourceInfo.source.context !== this.#audioContext) {
        teardownSource(sourceInfo.source);
        sourceInfo.source = new AudioBufferSourceNode(this.#audioContext, {
          loop: true,
          buffer: sourceInfo.source.buffer,
        });
      }
      if (this.#audioContext.state === "running") {
        sourceInfo.source.connect(this.#gainNode);
        sourceInfo.source.start();
        sourceInfo.state = "playing";
      } else {
        sourceInfo.state = "suspended";
      }
    } catch (e) {
      console.error(e);
      sourceInfo.state = "failed";
    }
  }

  async #unlockAutoplay() {
    if (!this.#autoplayUnlocked) {
      this.#isUnlocked = await checkAutoplayPolicy();
      if (!this.#autoplayUnlocked) {
        if (this.#audioContext.state === "suspended") {
          await Promise.all([
            new Promise((res) => setTimeout(res, 1000)),
            this.#audioContext.resume(),
          ]);
        }
        if (this.#audioContext.state === "running") {
          this.#isUnlocked = true;
        }
      }
    }

    if (!this.#autoplayUnlocked) {
      return;
    }

    if (this.#audioContext.state === "closed") {
      this.#audioContext = new AudioContext(audioContextOptions);

      const volume = this.#gainNode.gain.value;

      this.#gainNode = this.#audioContext.createGain();
      this.#gainNode.gain.value = volume;
      this.#gainNode.connect(this.#audioContext.destination);

      for (const sourceInfo of this.#loopingSources.values()) {
        if (sourceInfo.state === "playing") {
          sourceInfo.state = "failed";
        }
      }
    }

    if (this.#audioContext.state === "suspended") {
      await this.#audioContext.resume();
    }

    if (this.#audioContext.state === "running") {
      for (const sourceInfo of this.#loopingSources.values()) {
        if (sourceInfo.state === "suspended" || sourceInfo.state === "failed") {
          this.#playSource(sourceInfo);
        }
      }
    }
  }
}
