import { ID } from "@mb-pro-ui/utils/types";
import React, { createContext, useContext, useState } from "react";

type Cdec = { id: ID };
type Acknowledge = [Cdec[], (v: Cdec[]) => void];

const AcknowledgeContext = createContext<Acknowledge | undefined>(undefined);

const AcknowledgeProvider = (props: { children: React.ReactNode }) => (
  <AcknowledgeContext.Provider {...props} value={useState<Cdec[]>([])} />
);

const useAcknowledge = () => {
  const context = useContext(AcknowledgeContext);
  if (context === undefined) {
    throw Error(
      "Acknowledge context must be used within an AcknowledgeProvider",
    );
  }

  return context;
};

export { AcknowledgeProvider, useAcknowledge };
