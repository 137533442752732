import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { intToColor } from "./colors";
import { AnyIcon, Icon } from "./icons";

export const DialogBackButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    aria-label="back"
    sx={(theme) => ({
      position: "absolute",
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.white,
      "&:hover": { backgroundColor: "unset" },
    })}
    onClick={onClick}
    disableRipple
    size="large"
  >
    <ArrowBackIcon />
  </IconButton>
);

export const DialogCloseButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    aria-label="close"
    sx={(theme) => ({
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.white,
      "&:hover": { backgroundColor: "unset" },
    })}
    onClick={onClick}
    disableRipple
    size="large"
  >
    <CloseIcon />
  </IconButton>
);

export const SyledDialogTitle = ({
  children,
  sx = [],
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
}) => (
  <DialogTitle
    sx={[
      {
        padding: (theme) => theme.spacing(2),
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <span
      style={{
        display: "inline-block",
        maxWidth: "70%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </span>
  </DialogTitle>
);

const MapMarkerDialog = <
  T extends {
    id: string;
    icon: Icon | null;
    color: number;
    primaryColor?: string;
    alarm?: boolean;
  },
>({
  open,
  items,
  onClose,
  getTitle,
  renderSelected,
  renderActions,
  getPrimary,
  getSecondary,
}: {
  open: boolean;
  items: T[];
  onClose: () => void;
  getTitle: (item: T | null, items: T[]) => string;
  renderSelected: (item: T) => ReactNode;
  renderActions?: (item: T | null, items: T[]) => ReactNode;
  getPrimary?: (item: T) => string | undefined;
  getSecondary?: (item: T) => string | undefined;
}) => {
  const [selected, select] = useState<T | null>(null);
  useEffect(() => select(items.length === 1 ? items[0] : null), [items]);

  return (
    <Dialog open={open} onClose={onClose}>
      <SyledDialogTitle
        sx={
          selected?.primaryColor
            ? { bgcolor: selected?.primaryColor }
            : undefined
        }
      >
        {getTitle(selected, items)}
      </SyledDialogTitle>
      {selected && items.length > 1 && (
        <DialogBackButton onClick={() => select(null)} />
      )}
      <DialogCloseButton onClick={onClose} />
      <DialogContent
        sx={[{ minWidth: "600px" }, !selected && { minWidth: "450px", px: 0 }]}
      >
        {selected ? (
          renderSelected(selected)
        ) : (
          <List>
            {items.map((obj) => (
              <ListItemButton key={obj.id} onClick={() => select(obj)}>
                <ListItemIcon sx={{ minWidth: "30px", marginRight: "10px" }}>
                  {obj.icon && (
                    <AnyIcon
                      key={obj.id}
                      icon={obj.icon}
                      alarm={obj.alarm}
                      color={intToColor(obj.color)}
                      sx={{ width: "40px", height: "40px" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color={obj.primaryColor}
                      variant="body1"
                      component="span"
                      display="block"
                    >
                      {getPrimary?.(obj)}
                    </Typography>
                  }
                  secondary={getSecondary?.(obj)}
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </DialogContent>
      {renderActions?.(selected, items)}
    </Dialog>
  );
};

export default MapMarkerDialog;
