import { Button as MuiButton, styled } from "@mui/material";
import { ContentState, EditorState, Modifier } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CustomButtonProps, ToolbarComponentProps } from "./types";

export const RDWButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: "6px",
  position: "relative",
  flexWrap: "wrap",
});

export const RDWButton = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
    textDecoration: `underline ${theme.palette.primary.main}`,
    animation: "unset",
    backgroundColor: "unset",
  },
}));

export const RDWModal = styled("div")({
  position: "absolute",
  top: "35px",
  left: "5px",
  display: "flex",
  flexDirection: "column",
  width: "175px",
  border: "1px solid #F1F1F1",
  borderRadius: "2px",
  zIndex: "100",
  background: "white",
  boxShadow: "3px 3px 5px #BFBDBD",
});

const CustomEditorButton = ({
  editorState,
  modalHandler,
  onChange,
  render,
  buttonText,
}: ToolbarComponentProps & CustomButtonProps) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const handleEditorClick = useCallback(close, []);

  const toggleOpen = (e: any) => {
    e.stopPropagation();
    setOpen((o) => !o);
  };

  useEffect(() => {
    modalHandler.registerCallBack(handleEditorClick);
    return () => {
      modalHandler.deregisterCallBack(handleEditorClick);
    };
  }, [handleEditorClick, modalHandler]);

  const insert = (value: string) => {
    const currentContentState = editorState.getCurrentContent();
    const currentSelectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(
      currentContentState,
      currentSelectionState,
      value,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters",
    );
    onChange(newEditorState);
  };

  const insertHtml = (value: string) => {
    const { contentBlocks, entityMap } = htmlToDraft(value);

    const newContentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap(),
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters",
    );
    onChange(newEditorState);
  };

  return (
    <RDWButtonWrapper>
      <RDWButton type="button" onClick={toggleOpen}>
        {buttonText ?? formatMessage({ defaultMessage: "Insert Variable" })}
      </RDWButton>
      {open && <RDWModal>{render?.(insert, insertHtml)}</RDWModal>}
    </RDWButtonWrapper>
  );
};

export default CustomEditorButton;
