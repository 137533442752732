import SensorsIcon from "@mui/icons-material/Sensors";
import { SvgIcon } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { AccessControlRoutes } from "../types";

const useAccessControlMenuLinks = () => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => [
      {
        icon: <SvgIcon component={SensorsIcon} />,
        href: AccessControlRoutes.RFID,
        label: formatMessage({ defaultMessage: "RFID" }),
      },
    ],
    [formatMessage],
  );
};

export default useAccessControlMenuLinks;
