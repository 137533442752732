import moment, { MomentInput } from "moment-timezone";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useMe from "../hooks/useMe";

export const useTimezone = () => {
  const { data: me } = useMe();

  const timezone =
    me?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  return timezone;
};

const useTimestampFormat = (
  variant: "full" | "minutes" | "date" | "time" | "time_minutes" = "full",
) => {
  const { formatMessage } = useIntl();
  const timezone = useTimezone();

  const format =
    variant === "full"
      ? formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" })
      : variant === "minutes"
        ? formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm" })
        : variant === "date"
          ? formatMessage({ defaultMessage: "DD/MM/YYYY" })
          : variant === "time"
            ? formatMessage({ defaultMessage: "HH:mm:ss" })
            : variant === "time_minutes"
              ? formatMessage({ defaultMessage: "HH:mm" })
              : formatMessage({ defaultMessage: "DD/MM/YYYY HH:mm:ss" });

  const formatTimestamp = useCallback(
    (ts: MomentInput) => {
      if (!ts) {
        return null;
      }
      const m = moment.isMoment(ts) ? ts : moment(ts);
      return m.isValid() ? m.tz(timezone).format(format) : null;
    },
    [format, timezone],
  );

  return { format, formatTimestamp, timezone };
};

export default useTimestampFormat;
