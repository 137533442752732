import SvgIcon from "@mui/icons-material/Memory";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { DispatcherRoutes } from "../components/types";
import { ReactComponent as CustomerManagerMenuIcon } from "../icons/CustomerManagerMenu.svg";
import { ReactComponent as EventsMenuIcon } from "../icons/EventsMenu.svg";
import { ReactComponent as ExportsMenuIcon } from "../icons/ExportsMenu.svg";
import { ReactComponent as InterventionsMenuIcon } from "../icons/InterventionsMenu.svg";
import { ReactComponent as MapMenuIcon } from "../icons/MapMenu.svg";
import { ReactComponent as NotificationsMenuIcon } from "../icons/NotificationsMenu.svg";
import { ReactComponent as OperatorMenuIcon } from "../icons/OperatorMenu.svg";
import { ReactComponent as SystemSettingsMenuIcon } from "../icons/system_settings/SystemSettings.svg";

const useAlarmMenuLinks = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        icon: <SvgIcon component={OperatorMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.AlarmOperator,
        label: formatMessage({
          defaultMessage: "Center",
          description: "Main menu Monitoring label",
        }),
      },
      {
        icon: (
          <SvgIcon component={CustomerManagerMenuIcon} viewBox="0 0 50 50" />
        ),
        href: DispatcherRoutes.Customers,
        label: formatMessage({
          defaultMessage: "Customers",
          description: "Main menu Customers label",
        }),
      },
      {
        icon: <SvgIcon component={EventsMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.Events,
        label: formatMessage({
          defaultMessage: "Events",
          description: "Main menu Events label",
        }),
      },
      {
        icon: <SvgIcon component={InterventionsMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.Interventions,
        label: formatMessage({
          defaultMessage: "Interventions",
          description: "Main menu Interventions label",
        }),
      },
      {
        icon: <SvgIcon component={ExportsMenuIcon} />,
        href: DispatcherRoutes.Exports,
        label: formatMessage({
          defaultMessage: "Exports",
          description: "Main menu Exports label",
        }),
      },
      {
        icon: <SvgIcon component={MapMenuIcon} viewBox="0 0 50 50" />,
        href: DispatcherRoutes.Map,
        label: formatMessage({
          defaultMessage: "Map",
          description: "Main menu Map label",
        }),
      },
      {
        icon: <SvgIcon component={NotificationsMenuIcon} />,
        href: DispatcherRoutes.Notifications,
        label: formatMessage({
          defaultMessage: "SMS/Email",
          description: "Main menu Notifications label",
        }),
      },
      {
        icon: <SvgIcon component={SystemSettingsMenuIcon} />,
        href: DispatcherRoutes.SystemSettings,
        label: formatMessage({
          defaultMessage: "Settings",
          description: "Main menu System Settings label",
        }),
      },
    ],
    [formatMessage],
  );
};

export default useAlarmMenuLinks;
