import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const BootstrapTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & { backgroundColor?: string; border?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== "backgroundColor" && prop !== "color" && prop !== "border",
  },
)(({ theme, backgroundColor, color, border }) => {
  let bColor = backgroundColor;
  switch (backgroundColor) {
    case "primary":
    case "info":
    case "warning":
    case "error":
      bColor = theme.palette[backgroundColor].main;
  }
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bColor,
      color,
      border,
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
  };
});

export default BootstrapTooltip;
