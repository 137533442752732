import { Button } from "@mb-pro-ui/components";
import { RichEditor, TextField } from "@mb-pro-ui/components/form";
import { useApi, useGetAll } from "@mb-pro-ui/utils";
import { ManualEmailTemplate } from "@mb-pro-ui/utils/types/alarm";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useSimpleFieldValue } from "../../pages/measurements/FieldValue";
import { useInterventionEvents } from "../intervention/useInterventionEvents";
import { StyledForm } from "../settings/utils";
import EmailInsertButton from "./EmailInsertButton";
import { InsertDataButton } from "./InsertDataButton";
import {
  Email,
  EmailModalProps,
  Template,
  TemplateContext,
  WithMeta,
} from "./types";

const templateVariableTest = /%{[^}]+}/;

const EmailTemplateWarnings = () => {
  const { formatMessage } = useIntl();

  const html = useSimpleFieldValue<string>({ name: "html" }) ?? "";
  const subject = useSimpleFieldValue<string>({ name: "subject" }) ?? "";

  return templateVariableTest.test(html) ||
    templateVariableTest.test(subject) ? (
    <Typography variant="caption" color="error" fontWeight={700}>
      {formatMessage({
        defaultMessage:
          "The email seems to contain unresolved template variables.",
      })}
    </Typography>
  ) : null;
};

const EmailModal = ({
  open,
  onClose,
  setSnackbarState,
  customer,
  intervention,
  type,
  initialValues,
  templateContext = type,
}: EmailModalProps) => {
  const api = useApi();
  const { formatMessage } = useIntl();
  const { id: contextId } = useParams<{ id: string }>();
  const [template, setTemplate] = useState<Template>();

  const [templateAnchor, setTemplateAnchor] = useState<null | HTMLElement>(
    null,
  );
  const menuOpen = Boolean(templateAnchor);

  const close = () => {
    onClose();
    setTemplate(undefined);
  };

  const { data: cdecs } = useInterventionEvents(intervention, ["id"]);

  const { data: templates } = useGetAll<ManualEmailTemplate>(
    "alarm/manual-email-templates",
    {
      page: {
        limit: 10000,
      },
      filter: {
        active: {
          is: "true",
        },
        context: {
          "contained-by": templateContext,
        },
      },
      sort: ["id"],
    },
  );

  const send = (body: Email) =>
    api("/actions/alarm/send-email", {
      method: "POST",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
      body: JSON.stringify(body),
    });

  const render = ({ id, body }: { id: string; body: TemplateContext }) =>
    api<Template>(`/actions/alarm/render-template/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
      body: JSON.stringify(body),
    });

  const { mutate: doRender, isLoading: isRendering } = useMutation(render, {
    onSuccess: (data) => {
      setTemplate(data);
    },
  });

  const { mutate: doSendEmail } = useMutation(send, {
    onMutate: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Email sending..." }),
      });
    },
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Email sent" }),
      });
      close();
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Failed to send" }),
        error: true,
      });
    },
  });

  const onSubmit = (values: Email) => {
    let email: WithMeta<Email> = {
      ...values,
      meta: {
        intervention: intervention?.id,
        customer: customer?.id,
        primary: type,
      },
    };
    doSendEmail(email);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setTemplateAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setTemplateAnchor(null);
  };

  const handleTemplateClick = (id: string) => {
    let body: TemplateContext = {};
    if (type === "intervention") {
      body.interventionId = contextId;
    } else if (type === "customer") {
      body.customerId = contextId;
    }
    doRender({ id, body });
    setTemplateAnchor(null);
  };

  const initialValuesMemo = useMemo(
    () => ({
      ...template,
      ...initialValues,
    }),
    [template, initialValues],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValuesMemo}
      render={({ handleSubmit, form: { reset } }) => {
        return (
          <Dialog open={open} onClose={close} fullWidth maxWidth="md">
            <DialogTitle>
              {formatMessage({ defaultMessage: "Send Email" })}
            </DialogTitle>
            <DialogContent dividers>
              <StyledForm onSubmit={handleSubmit} id="emailForm">
                <Button
                  sx={{
                    alignSelf: "flex-start",
                    m: 1,
                  }}
                  onClick={handleClick}
                  endIcon={
                    isRendering ? (
                      <CircularProgress
                        size={18}
                        sx={{
                          color: (theme) => theme.palette.common.white,
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                >
                  <FormattedMessage defaultMessage="Insert template" />
                </Button>
                <Menu
                  anchorEl={templateAnchor}
                  open={menuOpen}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {templates ? (
                    templates.map((tmp) => (
                      <MenuItem
                        key={tmp.id}
                        onClick={() => {
                          handleTemplateClick(tmp.id);
                        }}
                      >
                        {tmp.description}
                      </MenuItem>
                    ))
                  ) : (
                    <CircularProgress />
                  )}
                </Menu>
                <TextField
                  required
                  name="to"
                  multiline
                  label={formatMessage({ defaultMessage: "Address(es)" })}
                  InputProps={{
                    endAdornment: <EmailInsertButton customer={customer} />,
                  }}
                />
                <TextField
                  name="subject"
                  required
                  label={formatMessage({ defaultMessage: "Email subject" })}
                />
                <RichEditor
                  name="html"
                  label={formatMessage({ defaultMessage: "Content" })}
                  sx={{ pl: 1, pt: 0, pr: 1 }}
                  toolbarCustomButtons={[
                    <InsertDataButton
                      key="insert-data-button"
                      customer={customer}
                      intervention={intervention}
                      cdecs={cdecs}
                    />,
                  ]}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      "fontFamily",
                      "list",
                      "colorPicker",
                      "link",
                      "image",
                      "remove",
                      "history",
                    ],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough"],
                    },
                  }}
                />
              </StyledForm>
            </DialogContent>
            <DialogActions>
              <EmailTemplateWarnings />
              <Button
                startIcon={<ReplayIcon />}
                variant="outlined"
                onClick={() => {
                  setTemplate(undefined);
                  reset();
                }}
                size="small"
              >
                {formatMessage({ defaultMessage: "Reset" })}
              </Button>
              <Button variant="outlined" type="button" onClick={close}>
                {formatMessage({ defaultMessage: "Cancel" })}
              </Button>
              <Button
                type="submit"
                form="emailForm"
                startIcon={<SendIcon fontSize="small" />}
              >
                {formatMessage({ defaultMessage: "Send" })}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
};

export default EmailModal;
