import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { Button, ButtonProps, styled } from "@mui/material";
import { MouseEvent, ReactNode } from "react";
import { useIntl } from "react-intl";
import { Link, LinkProps } from "react-router-dom";

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  display: "block",
  height: "100%",
});

interface LinkButtonProps<T extends {}> {
  url?: LinkProps<T>["to"];
  icon?: ReactNode;
  message?: ReactNode;
  onClick?: () => void;
}

const LinkButton = <T extends {}, C extends React.ElementType>({
  url,
  icon,
  message,
  onClick,
  href,
  ...props
}: LinkButtonProps<T> & ButtonProps<C, { component?: C }>) => {
  const { formatMessage } = useIntl();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick?.();
  };

  return (
    <Button
      {...(!onClick &&
        !href && {
          component: StyledLink,
          to: url,
        })}
      onClick={handleClick}
      href={href}
      size="small"
      style={{ height: "24px", borderRadius: 0 }}
      startIcon={icon ?? <EditTwoToneIcon fontSize="inherit" />}
      {...props}
    >
      {message ??
        formatMessage({
          defaultMessage: "Edit",
          description: "LinkButton Edit",
        })}
    </Button>
  );
};

export default LinkButton;
